import React, { MouseEvent } from 'react'
import { Form, Button, Tab, Modal, Header, Message, Grid, Segment, Checkbox, Popup, Input } from 'semantic-ui-react'

import './style.less'
import { UserRole, Registrant, Unit, User } from '../../../../types'
import DataSourceDropdown from '../../../../components/DataSourceDropdown'
import FamilyInvite from '../../../../components/FamilyInvite'
import SmartGadgets from '../../../../components/SmartGadgets'
import AlisIntegration from '../../../../components/AlisIntegration'
import { sendSmsInvite, sendEmailInvite, deleteSmsInvite } from '../../../../services/SnsService'
import RegistrantRelative from './RegistrantRelatives'
// import DeviceNotifications from '../../../../pages/AdminPanel/DeviceNotifications'
import { Gadget } from '../../../../types';
import { validateMoveRoom } from '../../../../services/Units'
import { toast } from 'react-toastify'
import { deleteRelativeContact } from '../../../../services/Registrants'
import SegmentedInputBox from '../../../../components/SegmentedInputBox'
import RequestsTextListWithFilter from '../../../../components/RequestsTextList'
import PrintExportIcons from '../../../../components/PrintExportIcons'
import ResidentProfile from '../../../../components/ResidentProfile'
import ResidentDietery from '../../../../components/ResidentDietery'
import ResidentAlexa from '../../../../components/ResidentAlexa'
import ResidentAnalzye from '../../../../components/ResidentAnalyze'
import ResidentAttendance from '../../../../components/ResidentAttendance/index'
import ResidentCompanion from '../../../../components/ResidentCompanion/index'
import ResidentStaffCalendarComponent from '../../../../components/ResidentCalendar'
import ResidentQOTDResponses from '../../../../components/ResidentQOTDResponses'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SegmentedTextArea from '../../../../components/SegmentedTextArea'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { throwCorrespondingExternalIntegrationError } from '../../../../util/externalintegrations';

interface State {
    registrantData: Partial<Registrant>
    selectedUnit: Unit | null
    role: UserRole | null
    PrimaryCaregiver: User | string | null
    invite: string
    success: string
    error: string
    moveError: string | null
    isSubmittingInvite: boolean
    activeIndex: undefined | number | string
    gadgets: Gadget[] | []
    openMoveModal: boolean
    disableMove: boolean
    disableSave: boolean
    reloadList: boolean
    enableAnnouncementAlerts: boolean
    email: string | undefined
    initialRegistrantData: Partial<Registrant>
    inactiveResident: boolean
    residentRPCheckboxState: {
        rp: boolean
        resident: boolean
    },
    AdditionalExtReqIntegrationDeviceId: string,
    AdditionalExtReqIntegrationDeviceIds: Array<string>
    isExternalResident?: boolean
    isAlisResident?: boolean
    disabledPopUpMessage?: string
}
interface Props extends RouteComponentProps {
    units: Unit[]
    staffUsers: (User | { _id: string; FirstName: string })[]
    registrant: Registrant | null
    isFetching: boolean
    hasError: boolean
    error: string | null
    isSaving: boolean
    onSave: (registrantData: Partial<Registrant>, unit: Unit | null) => any
    cancel: () => any
    onDelete?: (registrantId: string) => any
    activeIndex?: number
    feedRefreshKey?: number
}
class RegistrantEditor extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            registrantData: {},
            role: null,
            selectedUnit: (this.props.registrant && this.props.registrant.Unit) || null,
            PrimaryCaregiver: (this.props.registrant && this.props.registrant.PrimaryCaregiver) || null,
            invite: '',
            success: '',
            error: '',
            moveError: '',
            isSubmittingInvite: false,
            activeIndex: this.props.activeIndex || 0,
            gadgets: [],
            openMoveModal: false,
            disableMove: true,
            disableSave: true,
            reloadList: false,
            enableAnnouncementAlerts: false,
            email: '',
            initialRegistrantData: {},
            inactiveResident: false,
            residentRPCheckboxState: {
                rp: false,
                resident: true  // by default Resident is true
            },
            AdditionalExtReqIntegrationDeviceId: '',
            AdditionalExtReqIntegrationDeviceIds: []
        }
    }

    componentDidMount(): void {
        const inactiveResident = (this.props.registrant && typeof this.props.registrant.IsActive === 'number' && !this.props.registrant.IsActive)
        inactiveResident && this.setState({
            inactiveResident
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.registrant !== this.props.registrant) {
            const inactiveResident = (this.props.registrant && typeof this.props.registrant.IsActive === 'number' && !this.props.registrant.IsActive)
            inactiveResident && this.setState({
                inactiveResident
            });
            this.setState({
                isExternalResident: (this.props.registrant && this.props.registrant.ExternalIntegrationSrc) ? true : false,
                isAlisResident: (this.props.registrant && this.props.registrant.alisResidentId) ? true : false,
                disabledPopUpMessage: (this.props.registrant && throwCorrespondingExternalIntegrationError({
                    ...(this.props.registrant.ExternalIntegrationSrc && { isExternalResident: true, externalIntegrationSource: this.props.registrant.ExternalIntegrationSrc }),
                    ...(this.props.registrant.alisResidentId && { isAlisResident: true }),
                    action: 'update'
                })) || ''
            })
        }
    }

    setAdditionalExtReqIntegrationDeviceId(value: string) {
        this.setState({
            AdditionalExtReqIntegrationDeviceId: value
        });
    }

    setRegistrantDataField(key: keyof Registrant, value: any) {
        if (key === 'AdditionalExtReqIntegrationDeviceIds') {
            value = [...(this.state.registrantData.AdditionalExtReqIntegrationDeviceIds ? this.state.registrantData.AdditionalExtReqIntegrationDeviceIds : []), value];
            this.setState({
                AdditionalExtReqIntegrationDeviceIds: value,
                AdditionalExtReqIntegrationDeviceId: ''
            });
        }
        this.setState({
            registrantData: {
                ...this.state.registrantData,
                [key]: value,
            },
            disableSave: false
        })
    }

    handleRemoveItem(index: number) {
        const items = this.state.registrantData.AdditionalExtReqIntegrationDeviceIds || [];
        items.splice(index, 1);
        this.setState({
            AdditionalExtReqIntegrationDeviceIds: items
        });
        this.setState({
            registrantData: {
                ...this.state.registrantData,
                AdditionalExtReqIntegrationDeviceIds: items,
            },
            disableSave: false
        });
    }

    handleEditItem(index: number) {
        if(this.state.AdditionalExtReqIntegrationDeviceId) { //This is to prevent lose of the current selected item
            const items = this.state.registrantData.AdditionalExtReqIntegrationDeviceIds || [];
            items.push(this.state.AdditionalExtReqIntegrationDeviceId);
            this.setState({
                AdditionalExtReqIntegrationDeviceIds: items
            });
        } 
        const items = this.state.registrantData.AdditionalExtReqIntegrationDeviceIds || [];
        const item = items[index];
        this.setState({
            AdditionalExtReqIntegrationDeviceId: item
        });
        items.splice(index, 1);
        this.setState({
            AdditionalExtReqIntegrationDeviceIds: items
        });
        this.setState({
            registrantData: {
                ...this.state.registrantData,
                AdditionalExtReqIntegrationDeviceIds: items,
            },
            disableSave: true
        });
    }

    setStateDataField(key: keyof State, value: any) {
        this.setState({
            [key]: value,
        } as Pick<State, keyof State>)
    }

    getRoomNumberFromUnit(unitId: string): string {
        const unit = this.props.units.find((u) => u._id === unitId)
        return unit ? unit.Name : ''
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (Object.keys(state.registrantData).length) return null
        if (!props.registrant) return null

        return {
            registrantData: props.registrant,
            selectedUnit: props.registrant.Unit,
            gadgets: props.registrant.Gadgets,
            initialRegistrantData: props.registrant
        }
    }

    async handleSubmit() {
        this.props.onSave(this.state.registrantData, this.state.selectedUnit)
        this.setState({
            openMoveModal: false
        })
    }

    async handleDelete(e: MouseEvent) {
        e.preventDefault()
        if (!this.canDelete) return
        // TODO: use proper confirm here
        const result = window.confirm('Are you sure you want to remove this Resident?')
        if (!result) return
        if (this.props.onDelete && this.state.registrantData && this.state.registrantData._id) {
            this.props.onDelete(this.state.registrantData._id)
        }
    }

    handleAnnouncementAlert = (value: boolean) => {
        this.setState({
            enableAnnouncementAlerts: value
        })
    }

    handleEmail = (value) => {
        this.setState({
            email: value
        })
    }


    handleFamilyInviteCheckboxChange = (name) => {
        this.setState(prevState => ({
            residentRPCheckboxState: {
                // Reset both to false initially
                rp: false,
                resident: false,
                // Then toggle the clicked one based on its previous state
                ...{ [name]: !prevState.residentRPCheckboxState[name] }
            }
        }));
    };
    


    async handleSubmitInvite(e: MouseEvent): Promise<void> {
        e.preventDefault()
        const setIsSubmitting = (value) => {
            this.setState({ isSubmittingInvite: value })
        }
        const setInvitedManager = () => {
            this.setState({
                registrantData: {
                    ...this.state.registrantData,
                    invitedManager: {
                        Contact: !this.state.invite || this.state.invite === '' ? (this.state.registrantData && this.state.registrantData.invitedManager && this.state.registrantData.invitedManager.Contact) : this.state.invite
                    }
                },
            })
        }

        setIsSubmitting(true)
        const inviteContact = btoa(!this.state.invite
            ? (this.state.registrantData && this.state.registrantData.invitedManager && this.state.registrantData.invitedManager.Contact) || ''
            : this.state.invite);
        const registrantId = btoa((this.props.registrant && this.props.registrant._id) || '')
        const firstName = btoa((this.props.registrant && this.props.registrant.FirstName) || '')
        const lastName = btoa((this.props.registrant && this.props.registrant.LastName) || '')
        const image = btoa((this.props.registrant && (this.props.registrant.Image || this.props.registrant.alisImage || this.props.registrant.ExternalResidentImage)) || '')

        const setError = (error) => {
            this.setState({ error, success: '' })
        }

        const setSuccess = (success) => {
            this.setState({ success, error: '' })
        }
        if (!inviteContact || !registrantId) { // this could be redundant for inviteContact, check during maintenance
            setError('Please provide valid invite contact details.')
            setIsSubmitting(false)
            return
        }

        interface InviteObject {
            c: string; // invite contact
            r: string; // registrant id
            f: string; // first name
            l: string; // last name
            i: string; // image
            isOriginalManager: boolean;
            announcementAlerts: boolean;
            email: string | undefined;
            managerType?: string;
        }
        
        const inviteObject: InviteObject = {
            c: inviteContact,
            r: registrantId,
            f: firstName,
            l: lastName,
            i: image,
            isOriginalManager: true,
            announcementAlerts: this.state.enableAnnouncementAlerts,
            email: this.state.email
        };
        
        if(this.state.residentRPCheckboxState.rp) {
            inviteObject.managerType = "Relative";
        }
        
        if(this.state.residentRPCheckboxState.resident) {
            inviteObject.managerType = "Resident";
        }

        const invitationOptions = {
            email: async function () {
                try {
                    inviteObject.c = btoa(atob(inviteObject.c).toLowerCase())
                    const emailResponse = await sendEmailInvite(inviteObject)
                    if (emailResponse === 'This person is already invited.') {
                        setError('This person is already invited.')
                    } else if (emailResponse.error) {
                        setError('Could not send email.')
                    } else {
                        setSuccess('Invite sent successfully.')
                    }
                } catch (error) {
                    setError('Could not send email.')
                }
                setIsSubmitting(false)
            },
            phone: async function () {
                try {
                    const smsResponse = await sendSmsInvite(inviteObject)
                    if (smsResponse.error) {
                        toast.error(`${`Could not send SMS`}`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success('Invite Sent Successfully', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } catch (error) {
                    setError('Could not send sms.')
                }
                setInvitedManager()
                setIsSubmitting(false)
            },
        }
        const option = this.validateInvite(this.state.invite)
        if (option) {
            invitationOptions[option]()
        } else {
            setIsSubmitting(false)
        }

    }

    async handleDeleteInvite(e: MouseEvent) {
        this.setState({
            isSubmittingInvite: true
        })
        e.preventDefault()
        const deleteSmsInviteResponse = await deleteSmsInvite(this.props.registrant && this.props.registrant._id)
        if (deleteSmsInviteResponse.success) {
            toast.success('Successfully deleted the invite', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.error('Could not delete the invite - please try again', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
        this.setState({
            isSubmittingInvite: false,
            registrantData: {
                ...this.state.registrantData,
                invitedManager: null
            },
            invite: ''
        })
    }

    handleCancel(e: MouseEvent) {
        e.preventDefault()
        this.props.cancel()
    }

    validateInvite(value: string) {
        const emailTest = /\S+@\S+\.\S+/
        if (emailTest.test(value)) return 'email'
        const phoneTest = /^[0-9+?]*$/
        if (phoneTest.test(value)) return 'phone'
        return false
    }

    get validEmail() {
        if (!this.state.registrantData.Email || this.state.registrantData.Email.length < 1) return true
        const re = /\S+@\S+\.\S+/
        return re.test(this.state.registrantData.Email)
    }

    get validForm() {
        return (
            this.validEmail &&
            this.state.registrantData.FirstName &&
            this.state.registrantData.LastName
        )
    }

    get canDelete() {
        return this.props.onDelete && this.state.registrantData && this.state.registrantData._id
    }

    async removeRegistrantManagerHandler() {
        try {
            this.setState({
                isSubmittingInvite: true
            })
            const RegistrantId = this.state.registrantData._id
            const ManagerId = this.state.registrantData && this.state.registrantData.Manager && this.state.registrantData.Manager.id
            if (RegistrantId && ManagerId) {
                await deleteRelativeContact({ RegistrantId, RelativeId: ManagerId })
                this.setState({
                    registrantData: {
                        Manager: null,
                    },
                    isSubmittingInvite: false
                })
                toast.success('Original Manager Deleted  Successfully', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                this.setState({ reloadList: true })
            } else {
                this.setState({
                    error: "Registrant ID  or Manager ID was not provided",
                    isSubmittingInvite: false,
                })
            }

        } catch (error) {
            toast.error("Failed to delete original manager", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    removeOriginalManagerFromFamilyInvite() {
        this.setState({
            registrantData: {
                Manager: null
            }
        })
    }

    formatAndExportRegistrantData() {
        return [this.state.registrantData]
    }

    handleRPBox(registrantRelative) {
        console.log({ registrantRelative })
        const isOriginalManager = registrantRelative.isOriginalManager;
        if (isOriginalManager) {
            this.setState({
                registrantData: {
                    Manager: null,
                }
            })
        }
    }

    isResidentCreatePage() {
        return this.props.location.pathname === "/admin/registrants/create"
    }

    aboutPane = {
        menuItem: 'About',
        render: () => {
            return (
                <div style={{ marginTop: "10px" }}>
                    <Grid columns={6}>
                        <Grid.Column width={6}>
                            <Popup
                                content={this.state.disabledPopUpMessage}
                                disabled={!(this.state.isExternalResident || this.state.isAlisResident)}
                                trigger={
                                    <Form.Field>
                                        <SegmentedInputBox
                                            readonly={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}
                                            value={this.state.registrantData.FirstName || ''}
                                            required={true}
                                            label="First name"
                                            onChange={(value) => this.setRegistrantDataField('FirstName', value)}
                                            placeholder="First name"
                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content={this.state.disabledPopUpMessage}
                                disabled={!(this.state.isExternalResident || this.state.isAlisResident)}
                                trigger={
                                    <Form.Field>
                                        <SegmentedInputBox
                                            readonly={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}
                                            value={this.state.registrantData.LastName || ''}
                                            required={true}
                                            label="Last name"
                                            onChange={(value) => this.setRegistrantDataField('LastName', value)}
                                            placeholder="Last name"
                                        />
                                    </Form.Field>
                                }
                            />
                            <Segment size='small'>
                                <Form.Field disabled={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}>
                                    <label>Apartment</label>
                                    <Popup
                                        content='To relocate the resident, simply click the "Move Resident" button located at the bottom of this page.'
                                        disabled={!this.props.onDelete}
                                        trigger={
                                            <Form.Field>
                                                <DataSourceDropdown
                                                    disabled={!!this.props.onDelete || this.state.isExternalResident || this.state.isAlisResident} //disable only in edit mode
                                                    selected={this.state.registrantData.Unit || undefined}
                                                    placeholder="Apartment"
                                                    data={this.props.units}
                                                    labelFields={['Name']}
                                                    onSelectionChange={async (u: Unit) => {
                                                        this.setState({ error: '', disableSave: true })
                                                        if (await validateMoveRoom(u)) {
                                                            this.setState({
                                                                selectedUnit: u,
                                                                disableSave: false
                                                            })
                                                        } else {
                                                            this.setState({
                                                                error: 'Cannot add to this apartment. Ensure that the apartment doesn\'t have an occupant.',
                                                                disableSave: true
                                                            })
                                                        }
                                                    }
                                                    }
                                                />
                                            </Form.Field>
                                        }
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Email</label>
                                    <Input
                                        disabled={this.state.inactiveResident}
                                        value={this.state.registrantData.Email || this.state.registrantData.alisEmail || ''}
                                        error={!this.validEmail}
                                        placeholder="Email"
                                        type="email"
                                        name="registeremail"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('Email', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Cell</label>
                                    <PhoneInput
                                        style={{ padding: '5px' }}
                                        readOnly={this.state.inactiveResident}
                                        defaultCountry="US"
                                        value={this.state.registrantData.Cell || this.state.registrantData.alisPhone || ''}
                                        placeholder="Enter phone number"
                                        onChange={(phone) => {
                                            this.setRegistrantDataField('Cell', phone);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Landline</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}
                                        value={this.state.registrantData.Phone || ''}
                                        placeholder="Phone"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('Phone', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Address 1</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident}
                                        value={this.state.registrantData.Address1 || ''}
                                        placeholder="Address 1"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('Address1', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Address 2</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident}
                                        value={this.state.registrantData.Address2 || ''}
                                        placeholder="Address 2"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('Address2', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>City</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident}
                                        value={this.state.registrantData.City || ''}
                                        placeholder="City"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('City', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>State</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident}
                                        value={this.state.registrantData.State || ''}
                                        placeholder="State"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('State', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field>
                                    <label>ZIP</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident}
                                        value={this.state.registrantData.ZIP || ''}
                                        placeholder="ZIP"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('ZIP', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <SegmentedTextArea
                                readonly={this.state.inactiveResident}
                                label="Notes"
                                value={this.state.registrantData.Notes || ""}
                                onChange={(value, name) => {
                                    this.setRegistrantDataField("Notes", value);
                                }}
                                name="Notes"
                            />
                            <SegmentedTextArea
                                readonly={this.state.inactiveResident}
                                label="About Me"
                                value={this.state.registrantData.SpecialHandling || ''}
                                onChange={(value) => this.setRegistrantDataField('SpecialHandling', value)}
                            />
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Care Plan</label>
                                    <Input
                                        disabled={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}
                                        value={this.state.registrantData.CarePlan || ''}
                                        placeholder="Care Plan"
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            this.setRegistrantDataField('CarePlan', value);
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <Segment size='small'>
                                <Form.Field disabled={this.state.inactiveResident}>
                                    <label>Special Contact</label>
                                    <DataSourceDropdown
                                        selected={this.state.registrantData.PrimaryCaregiver || undefined}
                                        placeholder="Primary Caregiver"
                                        data={[{ _id: '1', FirstName: 'Other' }].concat(this.props.staffUsers)}
                                        labelFields={['FirstName', 'LastName']}
                                        onSelectionChange={(user) => {
                                            // If the _id of user is '1' representing "Other", than insert "Other" into db
                                            if (user._id === '1') {
                                                this.setRegistrantDataField('PrimaryCaregiver', 'Other')
                                                return
                                            }
                                            // If a regular user was selected, insert the users _id into db
                                            this.setRegistrantDataField('PrimaryCaregiver', user._id)
                                        }}
                                    />
                                </Form.Field>
                            </Segment>
                            <SegmentedInputBox
                                // todo change the below value once you get info
                                value={""}
                                placeholder="Special Handling"
                                onChange={(value) => this.setRegistrantDataField('SpecialHandling', value)} // This should not be special handling because special handling refers to About Me
                                label="Billing plan"
                                readonly={true}
                            />
                            {/* todo test the onChange changes form e.currentTarget.value to value */}
                            <SegmentedInputBox
                                readonly={this.state.inactiveResident}
                                value={this.state.registrantData.AmazonAccountID || ''}
                                error={!this.validEmail}
                                placeholder="Amazon Account ID"
                                type="email"
                                name="amazonid"
                                onChange={(value) => this.setRegistrantDataField('AmazonAccountID', value)}
                                label="Amazon Account"
                            />
                            <SegmentedInputBox
                                readonly={this.state.inactiveResident}
                                value={this.state.registrantData.Insurance || ''}
                                placeholder="Insurance"
                                onChange={(value) => this.setRegistrantDataField('Insurance', value)}
                                label="Insurance"
                            />
                            <SegmentedInputBox
                                readonly={this.state.inactiveResident}
                                value={this.state.registrantData.ExternalRequestIntegrationDeviceId || ''}
                                placeholder="Device Id"
                                onChange={(value) => this.setRegistrantDataField('ExternalRequestIntegrationDeviceId', value)}
                                label="Device ID"
                            />
                            <Segment size='small'>
                                <Form.Field>
                                    <label>Additional Device IDs</label>
                                    <div>
                                        {this.state.registrantData.AdditionalExtReqIntegrationDeviceIds && Array.isArray(this.state.registrantData.AdditionalExtReqIntegrationDeviceIds) && this.state.registrantData.AdditionalExtReqIntegrationDeviceIds.map((item, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div key={index}>{item}</div>
                                                <div>
                                                    <Button icon='edit' onClick={() => this.handleEditItem(index)} size='mini' style={{ background: 'none', border: 'none', padding: 0 }} />
                                                    <Button icon='trash alternate' onClick={() => this.handleRemoveItem(index)} size='mini' style={{ background: 'none', border: 'none', padding: 0 }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px' }}>
                                        <Input
                                            value={this.state.AdditionalExtReqIntegrationDeviceId}
                                            onChange={(e, data) => this.setAdditionalExtReqIntegrationDeviceId(data.value)}
                                            placeholder="Add new Device ID"
                                            style={{ marginRight: '5px' }}
                                        />
                                        <Button onClick={() => this.setRegistrantDataField('AdditionalExtReqIntegrationDeviceIds', this.state.AdditionalExtReqIntegrationDeviceId)} basic color='blue' size='medium'>Add</Button>
                                    </div>
                                </Form.Field>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            )
        },
    }
    panes = [
        {
            menuItem: 'Address Book',
            render: () => {
                return (
                    <>
                        {this.state.inactiveResident && <Message negative>This resident is no longer active</Message>}
                        {(!this.state.inactiveResident && this.props && this.props.registrant && this.props.registrant.Unit && (
                        <div className='address-book' style={{ marginTop: "10px" }}>
                                    <RegistrantRelative
                                        registrantId={this && this.props && this.props.registrant && this.props.registrant._id}
                                        activeIndex={this.state.activeIndex}
                                        registrant={this.props.registrant}
                                        reloadList={this.state.reloadList}
                                        removeOriginalManagerFromFamilyInvite={this.removeOriginalManagerFromFamilyInvite.bind(this)}
                                        handleRPBox={this.handleRPBox.bind(this)}

                                    >
                                        {/* children will be rendered in the app entered column */}
                                        <FamilyInvite
                                            invite={this.state.invite}
                                            manager={this.state.registrantData.Manager || null}
                                            invitedManager={this.state.registrantData.invitedManager || null}
                                            onInputChange={this.setStateDataField.bind(this)}
                                            submitInvite={this.handleSubmitInvite.bind(this)}
                                            deleteInvite={this.handleDeleteInvite.bind(this)}
                                            success={this.state.success}
                                            error={this.state.error}
                                            isSubmitting={this.state.isSubmittingInvite}
                                            removeRegistrantManagerHandler={this.removeRegistrantManagerHandler.bind(this)}
                                            enableAnnouncementAlerts={this.state.enableAnnouncementAlerts}
                                            onAnnouncementAlertChange={this.handleAnnouncementAlert}
                                            email={this.state.email}
                                            onEmailChange={this.handleEmail}
                                            handleFamilyInviteCheckboxChange={this.handleFamilyInviteCheckboxChange.bind(this)}
                                            residentRPCheckboxState={this.state.residentRPCheckboxState}
                                        />
                                    </RegistrantRelative>
                                </div>
                        )) || <Message negative>Resident should be assigned to a room to associate alexa contacts</Message>}
                    </>
                    )
                
            },
        },
        {
            menuItem: 'Feed',
            render: () => {
                return (
                    <>
                        {this.state.inactiveResident && <Message negative>This resident is no longer active</Message>}
                        {!this.state.inactiveResident && (
                            <div style={{ marginTop: "10px" }}>
                                {this.props.registrant ? (
                                    <RequestsTextListWithFilter
                                        residentId={this.state.registrantData._id}
                                        key={this.props.feedRefreshKey}
                                        residentName={`${this.state.registrantData.FirstName} ${this.state.registrantData.LastName}`}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            menuItem: 'Calendar',
            render: () => {
                return (
                    <>
                        {this.state.inactiveResident && <Message negative>This resident is no longer active</Message>}
                        {!this.state.inactiveResident && (
                            <div style={{ marginTop: "10px" }}>
                                <ResidentStaffCalendarComponent userProfile={this.props.registrant} />
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            menuItem: 'Attendance',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentAttendance residentId={this.props.registrant._id} residentName={`${this.state.registrantData.FirstName} ${this.state.registrantData.LastName}`} /> : <></>}
                    </div>
                )
            },
        },
        this.aboutPane,
        {
            menuItem: 'Profile',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentProfile inactiveResident={this.state.inactiveResident} residentId={this.props.registrant._id} residentName={`${this.props.registrant.FirstName || ""} ${this.props.registrant.LastName || ""}`} /> : <></>}
                    </div>
                )
            },
        },
        {
            menuItem: 'Dietary',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentDietery inactiveResident={this.state.inactiveResident} residentId={this.props.registrant._id} residentName={`${this.props.registrant.FirstName} ${this.props.registrant.LastName}`} /> : <></>}
                    </div>
                )
            },
        },
        {
            menuItem: 'Companion',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentCompanion residentId={this.props.registrant._id} residentName={`${this.state.registrantData.FirstName} ${this.state.registrantData.LastName}`} /> : <></>}
                    </div>
                )
            },
        },
        // { //Hide until implemented
        //     menuItem: 'Orders',
        //     render: () => {
        //         return (
        //             <div style={{ marginTop: "10px" }}>
        //                 <h1>Coming soon</h1>
        //             </div>
        //         )
        //     },
        // },
        {
            menuItem: 'Analyze',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentAnalzye residentId={this.props.registrant._id} residentName={`${this.props.registrant.FirstName || ""} ${this.props.registrant.LastName || ""}`} /> : <></>}
                    </div>
                )
            },
        },
        {
            menuItem: 'Alexa',
            render: () => {
                return (
                    <>
                        {this.state.inactiveResident && <Message negative>This resident is no longer active</Message>}
                        {!this.state.inactiveResident && (
                            <div style={{ marginTop: "10px" }}>
                                {this.props.registrant && this.props.registrant.Unit ? (
                                    <ResidentAlexa a4hRoomId={this.props.registrant.Unit.a4hRoomId || ""} />
                                ) : !this.props.isFetching ? (
                                    <Message negative>No room is associated with resident</Message>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            menuItem: 'Question Of The Day',
            render: () => {
                return (
                    <div style={{ marginTop: "10px" }}>
                        {this.props.registrant ? <ResidentQOTDResponses residentId={this.props.registrant._id} residentName={`${this.props.registrant.FirstName || ""} ${this.props.registrant.LastName || ""}`} /> : <></>}
                    </div>
                )
            },
        },
        // { //Hide until implemented
        //     menuItem: 'Routines',
        //     render: () => {
        //         return (
        //             <div style={{ marginTop: "10px" }}>
        //                 <h1>Coming soon</h1>
        //             </div>
        //         )
        //     },
        // },
    ]

    onlyAboutPane = [this.aboutPane]

    render() {
        const placeholderUrl = `${process.env.PUBLIC_URL}/avatar_placeholder.svg`
        const registrantRoomId = this.state.registrantData.Unit && this.state.registrantData.Unit.a4hRoomId
        const currentRoomName = (this.state.registrantData.Unit && this.state.registrantData.Unit.Name) || 'Not assigned'
        const residentName = (this.state.registrantData.FirstName + ' ' + this.state.registrantData.LastName) || ''

        const getExportData = () => {
            const { FirstName, LastName, Notes, Unit, CarePlan, Email, SpecialHandling, Cell, AmazonAccountID } = this.state.initialRegistrantData;
            const filteredObj = { FirstName, LastName, Notes, 'Unit Name': ((Unit && Unit.Name) || "NA"), CarePlan, Email, SpecialHandling, Cell, AmazonAccountID }
            return [filteredObj]
        }
        return (
            <>
                <div className="CreateUser">
                    <Form loading={this.props.isFetching} autoComplete="false">
                        {/* todo remove the below code once code review is done */}
                        {/* <Form.Field className="image-field">
                            <EditableImage
                                url={this.state.registrantData.Image || this.state.registrantData.alisImage}
                                placeholder={placeholderUrl}
                                onSelected={(newUrl) => this.setRegistrantDataField('Image', newUrl)}
                            />
                        </Form.Field> */}

                        <Tab
                            className="registrant-tab"
                            panes={this.isResidentCreatePage() ? this.onlyAboutPane : this.panes}
                            onTabChange={async (event, data) => {
                                this.setState({ activeIndex: data.activeIndex })
                            }}
                            activeIndex={this.state.activeIndex}
                        />

                        <Form.Field>
                            <div className="error-message">{this.props.hasError && this.props.error}</div>
                            <Message negative hidden={!this.state.error} content={this.state.error} />
                        </Form.Field>
                        {(this.isResidentCreatePage() && this.state.activeIndex === 0) || this.state.activeIndex === 4 ? (
                            <div className="button-holder">
                                <Button
                                    type="submit"
                                    primary
                                    loading={this.props.isSaving}
                                    disabled={this.props.isSaving || !this.validForm || this.state.disableSave || this.state.inactiveResident}
                                    onClick={() => {
                                        this.setState({
                                            initialRegistrantData: {
                                                ...this.state.registrantData
                                            },
                                            disableSave: true
                                        })
                                        this.handleSubmit()
                                    }}
                                >
                                    Save Resident
                                </Button>
                                <Button basic onClick={() => {
                                    this.setState({
                                        registrantData: {
                                            ...this.state.initialRegistrantData
                                        },
                                        disableSave: true
                                    })
                                }}>
                                    Cancel
                                </Button>
                                {this.canDelete && (
                                    <>
                                        <Button
                                            basic
                                            color="red"
                                            loading={this.props.isSaving}
                                            onClick={this.handleDelete.bind(this)}
                                            disabled={this.state.inactiveResident || this.state.isExternalResident || this.state.isAlisResident}
                                        >
                                            Remove Resident
                                        </Button>
                                        <Modal
                                            dimmer='blurring'
                                            onClose={() => this.setState({ openMoveModal: false })}
                                            onOpen={() => this.setState({ openMoveModal: true })}
                                            open={this.state.openMoveModal && !this.props.hasError} // Don't show modal if there is an error passed down from parent component
                                        >
                                            <Modal.Header>Move resident{residentName ? `: ${residentName}` : ''} </Modal.Header>
                                            <Modal.Content>
                                                <Modal.Description>
                                                    <Header>Current room: {currentRoomName}</Header>
                                                    <Message negative content={`Please be aware that notifications do not move with the resident. If you have any notifications set for this resident, they will be deleted and will not appear in the Alexa in the new room. If the Alexa in the current room has notifications on it, please clear them verbally by saying "Alexa, clear notifications"`} />
                                                    <Header>Move resident to room: </Header>
                                                    <DataSourceDropdown
                                                        selected={this.state.registrantData.Unit || undefined}
                                                        placeholder="Room"
                                                        data={this.props.units.filter(u => u._id !== (this.state.registrantData.Unit && this.state.registrantData.Unit._id))}
                                                        labelFields={['Name']}
                                                        onSelectionChange={async (u: Unit) => {
                                                            try {
                                                                this.setState({ moveError: '', disableMove: true })
                                                                const isValidMove = await validateMoveRoom(u)
                                                                if (isValidMove) {
                                                                    this.setState({
                                                                        selectedUnit: u,
                                                                        disableMove: false
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        moveError: 'Move blocked. Ensure that the room doesn\'t have an occupant.',
                                                                        disableMove: true,
                                                                    })
                                                                }
                                                            } catch (error) {
                                                                this.setState({
                                                                    moveError: error instanceof Error ? error.message : "Error validating move",
                                                                    disableMove: true
                                                                })
                                                                
                                                            }
                                                        }}
                                                    />
                                                </Modal.Description>
                                                {this.state.moveError ?
                                                    <Message
                                                        warning
                                                        content={this.state.moveError}
                                                    /> : <></>
                                                }
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button
                                                    basic
                                                    content="Cancel"
                                                    onClick={() => this.setState({ openMoveModal: false, moveError: '' })}>
                                                </Button>
                                                <Button
                                                    disabled={this.state.disableMove}
                                                    loading={this.props.isSaving}
                                                    basic
                                                    positive
                                                    content="Move"
                                                    onClick={this.handleSubmit.bind(this)}
                                                />
                                            </Modal.Actions>
                                        </Modal>
                                        <Button
                                            type="button"
                                            basic
                                            color="yellow"
                                            loading={this.props.isSaving}
                                            onClick={() => { this.setState({ openMoveModal: true }); }}
                                            disabled={this.state.inactiveResident || !!this.state.registrantData.primaryResidentId || this.state.isExternalResident || this.state.isAlisResident}
                                        >
                                            Move Resident
                                        </Button>
                                    </>
                                )}
                                {!this.isResidentCreatePage() ? <PrintExportIcons exportData={getExportData()} onPrintClick={() => { }} exportFileName={this.state.registrantData.FirstName ? this.state.registrantData.FirstName : ""} disablePrintButton={true} /> : <></>}

                            </div>
                        ) : (
                            <></>
                        )}
                    </Form>

                </div>
                {this.state.activeIndex === 0 &&
                    <>
                        {this.state.gadgets && this.state.gadgets.length ?
                            <div className="CreateUser features-container">
                                <SmartGadgets gadgets={this.state.gadgets} />
                            </div> : <></>
                        }
                        {/* <DeviceNotifications residentA4hRoomId={registrantRoomId || ''} view='list' /> */}
                    </>
                }
                {this.state.registrantData.alisResidentId &&
                    <AlisIntegration residentId={this.state.registrantData.alisResidentId} />
                }
            </>
        )
    }
}

export default withRouter(RegistrantEditor)
