import React, { FC, useState, useRef, useEffect } from 'react'
import { Button, Dimmer, Dropdown, Form, Image, Loader, Segment } from 'semantic-ui-react'
import { copyS3BirthdayImage, createBirthday, deleteBirthday, updateBirthday } from '../../../../services/Birthdays'
import S3FileUpload from '../../../../components/S3FileUpload'
import { getSignedBirthdayImageUrls, uploadFile } from '../../../../services/ImageUpload';
import AudioRecorder from '../../../../components/AudioRecorder'
import { checkDestPlayableWithTimeDelay, getUploadUrl, uploadToSignedUrl } from '../../../../services/PplOfTheMonth'
import "../style.less"
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import FilePicker from '../../../../components/S3FileUpload/FilePicker'
import styles from "../../../AdminPanel/Signage/Background.module.css";
import moment from 'moment-timezone';


interface Props {
    closeCreateBirthdayHandler: () => void;
    edit: boolean;
    name?: string;
    dob?: string;
    editId?: string;
    clickedDate: string;
    reload: () => void;
    imageUrl: string;
    audio:string;
    audioId:string;
    copy:boolean;
    copyClickHandler:(id) => void
    imageName:string
    calendarType: string[];
    allCalendarType: {
        key: string;
        text: string;
        value: string;
    }[];
    selectedBirthdayCalendarType: string[];
}
const BirthDayForm: FC<Props> = ({ closeCreateBirthdayHandler, edit, name, dob, editId, reload, clickedDate, imageUrl, imageName,  audio, audioId, copy, copyClickHandler, calendarType, allCalendarType, selectedBirthdayCalendarType }) => {
    const [birthdayData, setBirthDayData] = useState({
        name: name || "",
        dob: dob || clickedDate || new Date().toLocaleDateString(),
        imageName: imageName ||  "",
        imageUrl: imageUrl || "",
        audio: '',
        audioId: audioId || "",
        calendarType: [] as string[],
    })
    const [res, setRes] = useState({
        loading: false,
    })

    const [filePickerStatus, setFilePickerStatus] = useState({
        percentage: 0, 
        isUploading: false, 
        finished: false, 
        error: null
    });
    const [birthdayImageFile, setBirthdayImageFile] = useState<File>();
    const [imageFileUploadedUrl, setImageFileUploadedUrl] = useState("")
    const [localImgPath, setLocalImgPath] = useState<string | null>("")
    const [previewImgSize, setPreviewImgSize] = useState({
        Height: 0,
        Width: 0
    });
 
    useEffect(() => {
        if (editId) {
            setBirthDayData({
                ...birthdayData,
                calendarType: selectedBirthdayCalendarType
            })
        } else if (calendarType && !editId) {
            setBirthDayData({
                ...birthdayData,
                calendarType: calendarType
            })
        }
    }, [])

    const previewImgRef = useRef<any>({
        current: {
            clientHeight: 0,
            clientWidth: 0
        }
    });
    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'error') {
            toast.error(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const onChangeHandler = (e) => {
        setBirthDayData({
            ...birthdayData,
            [e.target.name]: e.target.value
        })
    }

    const audioBlobHandler = (audio) => {
        setBirthDayData({...birthdayData, audio})
    }
    
    const deleteClickHandler = async () => {
        if (editId) {
            try {
                setRes({ ...res, loading: true })
                await deleteBirthday(editId)
                setRes({ ...res, loading: false })
                reload()
                closeCreateBirthdayHandler()
            } catch (error) {
                if (error instanceof Error) {
                    closeCreateBirthdayHandler()
                    throw new Error(error.message)
                }
            }
        }
    }
    const uploadAudiofile = async () => {
      const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('birthdays')
       if (birthdayData.audio || birthdayData.audioId) {
            const file = new File([birthdayData.audio || birthdayData.audioId], audioId, {
                type: 'audio/wav',
            })
            await uploadToSignedUrl(file, signedUrl)
            return audioId
       }
    }
    const handleAudio = async (birthday) =>  {
        try {
            const audioId = await uploadAudiofile()
            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3')
                setBirthDayData({...birthdayData, audioId:destAudioId})
                let destPlayableExists
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'birthdays',
                        Math.pow(2, i) * 1000,
                    )
                    if (destPlayableExists) {
                        break
                    }
                }
                if (destPlayableExists) {
                    console.log('audio submitting', birthday, ' audioId:', destAudioId ? destAudioId : null)
                    try {
                        const {name, dob, imageName} = birthdayData
                        let res
                        if (!edit) {
                            const id = await createBirthday({ name, dob, image: imageName, audioId: destAudioId , calendarType});
                            if(!id) throw new Error("Failed to create birthday");
                            if (birthdayImageFile) {
                                const fileName = await uploadImageFile(id);
                                const res = await updateBirthday({ _id: id, image: fileName, name, dob, audioId: destAudioId });

                                if(!res) {
                                    throw new Error("Failed to update birthday image");
                                }
                            }
                        }

                        if(edit && editId){
                            res = await updateBirthday({_id:editId, name, dob, image: imageName, audioId: destAudioId})
                        }
                        setRes({
                            ...res,
                            loading: false,
                        })
                        reload()
                        closeCreateBirthdayHandler()
                    } catch (e) {
                        console.log(e, "error while handling audio")
                        throw new Error(e)
                    }
                } else {
                    setRes({loading: false})
                    throw new Error("'Failed to process audio. Please start over'")

                }
            } else {
                setRes({loading: false})
                throw new Error("Error uploading audio. Please start over")
            }
        } catch (e) {
            console.log(e, 'error handle audio')
            throw new Error(e)
        }
        return false
    }

    const uploadImageFile = async (birthdayId: string) => {
        const handleProgess = (number: number) => {
            setFilePickerStatus((prev) => ({...prev, percentage: number}));
        }
        if(birthdayImageFile) {
            const { fileName } = await uploadFile(`birthdays/${birthdayId}/images`, birthdayImageFile, handleProgess, true);
            return fileName;
        } else {
            throw new Error("Image file is not provided");
        }

    }

    const formSubmitHandler = async (e) => {
        e.preventDefault()
        setRes({ ...res, loading: true })
        const { name, dob, imageName, calendarType } = birthdayData
       try {
            if (!name || !dob) {
                setRes({ loading: false })
                throw new Error("please fill all the input fields")
            }
            if(copy){
                const {name, dob, imageName, audioId, calendarType} = birthdayData
                if(birthdayData.audio){
                    const res = await handleAudio(birthdayData)
                    reload()
                    closeCreateBirthdayHandler()
                    return
                }
                
                if(birthdayData.imageName){
                    const birthdayId = await createBirthday({ name, dob, audioId , calendarType});
                    if (editId) {
                        const res = await copyS3BirthdayImage(editId, birthdayId, imageName)
                    } else {
                        throw new Error("editId is required")
                    }
                    const res = await updateBirthday({_id:birthdayId, name, dob, audio, audioId ,image:imageName, calendarType})
                    closeCreateBirthdayHandler()
                    reload()
                    return
                }

                if(audioId){
                    let destPlayableExists
                    for (let i = 0; i < 6; i++) {
                        destPlayableExists = await checkDestPlayableWithTimeDelay(
                            audioId,
                            'birthdays',
                            Math.pow(2, i) * 1000,
                        )
                        if (destPlayableExists) {
                            break
                        }
                    }
                }
                
                try {
                    const id = await createBirthday({name, dob, image: imageName, audioId, calendarType});
                    if(!id) throw new Error("Failed to create birthday");
                    if(birthdayImageFile) {
                        const fileName = await uploadImageFile(id);
                        const res = await updateBirthday({ _id: id, image: fileName, name, dob , calendarType});
                        if(!res) {
                            throw new Error("Failed to update birthday image");
                        }
                    }
                } catch (e) {
                    console.error("copy error", e)
                    throw new Error(e)
                }
                reload()
                closeCreateBirthdayHandler()
                return
            }
            if (!edit) {
                if(birthdayData.audio || audioId) {
                    const res = await handleAudio(birthdayData)
                    return;
                }
                if(!birthdayData.audio) {
                    try {
                        const id = await createBirthday({ name, dob , calendarType})
                        if(!id) throw new Error("Failed to create birthday");
                        if(birthdayImageFile) {
                            const fileName = await uploadImageFile(id);
                            const res = await updateBirthday({ _id: id, image: fileName, name, dob, calendarType });

                            if(!res) {
                                throw new Error("Failed to update birthday image");
                            }
                        }
                        reload()
                        closeCreateBirthdayHandler()
                        setRes({
                            ...res,
                            loading: false
                        })
                    } catch (e) {
                        throw new Error(e)
                    }
                } 
            }
            if (edit) {
                try {
                    if(birthdayData.audio){
                        if(edit && editId){
                            const res = await handleAudio(birthdayData)
                        }
                    }
                    if(!birthdayData.audio){
                        if (edit && editId) {
                            const response = await updateBirthday({ _id: editId, dob, name, image: imageName, audioId, calendarType })
                        }
                    }
                    reload()
                    closeCreateBirthdayHandler()
                    setRes({
                        ...res,
                        loading: false
                    })
                } catch (e) {
                    throw new Error(e)
                }
            }
       } catch (error) {
            console.log(error.message, "error formSubmit handler")
            closeCreateBirthdayHandler()
            let errorMessage = "Something went Wrong please try again!"

            if(error instanceof Error){
                errorMessage = error.message.replace(/Error /, "")
            }
            setRes({loading: false})
            sendToast("error", errorMessage)

       }
    }
    function padLeadingZero(num) {
        if (num < 10) {
            return String(num).padStart(2, '0')
        }
        return num
    }

    async function handleFileUpload (url, editId)  {
        if (url) {
            const newFileName = url.split("/").pop()
            setBirthDayData({
                ...birthdayData,
                imageName: newFileName, 
            })

            if(editId) {
                setRes({loading: true})
                const newSignedImageUrl = await getSignedBirthdayImageUrls(editId, newFileName)
                setImageFileUploadedUrl(newSignedImageUrl)
            }
        }
    }
    const validForm = () => {
        if (!birthdayData.name || !birthdayData.dob) {
            return false
        } else {
            return true
        }
    }

    const handleFileSelect = (files: File[]) => {
        const file = files[0]
        setBirthdayImageFile(file)

        const reader = new FileReader()
        setRes({ loading: true })
        reader.onload = () => {
            if (reader && reader.result) {
                setRes({ loading: false })
                setLocalImgPath(String(reader.result))
            }
        }

        reader.readAsDataURL(file)
    }
    const bottomleftFontSize = (previewImgSize.Height * 2) / 21;
    const topleftFontSize = bottomleftFontSize * 1.5;
    const bottomleftsubFontSize = bottomleftFontSize / 2;
    const topleftPosition = (bottomleftFontSize * 1.5 / 3) + (bottomleftFontSize * 1.5 / 3 * 300 / 570);
    const bottomleftPosition = (bottomleftFontSize) + (bottomleftFontSize * 300 / 570);
    const bottomleftsubPosition = (bottomleftFontSize / 2.5) + (bottomleftFontSize / 2.5 * 1 / 570);
    const letterSpacing = (bottomleftFontSize * 0.05)
    return <div>
        <Dimmer active={res.loading} inverted>
            <Loader active={res.loading} />
        </Dimmer>
        <Form onSubmit={formSubmitHandler} className="audio-or-text">
            <h3 className='ui header text-center'>{edit ? "Update" : "Create"} Birthday</h3>
           <div className="audio-recorder">
           <Form.Field>
                <AudioRecorder passAudioBlob={audioBlobHandler}  />
                {audio && edit && <div> 
                    <p>Current audio</p>
                    <audio controls src={audio}><code>your current browser does not support audio</code></audio>
                    </div>}
                    { audio && copy && <div> 
                    <p>Current audio</p>
                    <audio controls src={audio}><code>your current browser does not support audio</code></audio>
                    </div>}
            </Form.Field>
           </div>
            <Form.Field required={true}>
                <input placeholder='Name' name='name' onChange={onChangeHandler} value={birthdayData.name} />
            </Form.Field>
            <Form.Field style={{ width: '300px', marginTop: '17px' }} required={true}>
                <div className="customDatePickerWidth">
                    <DatePicker
                        style={{ border: '1px solid #183466' }}
                        placeholderText="Select a birth Date"
                        dateFormat="MMMM d, Y"
                        selectsStart
                        selected={birthdayData.dob && moment(birthdayData.dob).toDate()} 
                        onChange={(date) => {
                            const dateString = `${date.getFullYear()}-${padLeadingZero(date.getMonth() + 1)}-${padLeadingZero(date.getDate())}`;
                            setBirthDayData({
                                ...birthdayData,
                                dob: dateString
                            });
                        }}
                    />
                </div>
            </Form.Field>
            {
                allCalendarType && allCalendarType.length > 0 && (
                    <Form.Field style={{ width: '300px' }} required={true}>
                        <Dropdown
                            placeholder="Select Room Type"
                            fluid
                            multiple
                            selection
                            options={allCalendarType}
                            value={birthdayData.calendarType}
                            onChange={(e, d) => {
                                setBirthDayData({
                                    ...birthdayData,
                                    calendarType: d.value as string[]
                                })
                            }}
                        />
                    </Form.Field>
                )
            }
            <Form.Field>
                {imageFileUploadedUrl && <Image rounded src={imageFileUploadedUrl} onLoad={() => {
                    setRes({loading: false})
                }} />}
                {(localImgPath || imageUrl) && <>
                    <div className={styles.container}>
                        <img
                            onLoad={() => setPreviewImgSize({ Height: previewImgRef.current.clientHeight, Width: previewImgRef.current.clientWidth })}
                            className={styles.image}
                            ref={previewImgRef}
                            src={localImgPath || imageUrl} />
                        <div
                            className={styles.topleft}
                            style={{ fontSize: `${topleftFontSize}px`, top: `${topleftPosition}px` }}>
                            {new Date().getHours()} : {new Date().getMinutes()}
                        </div>
                        <div
                            className={styles.bottomleft}
                            style={{ fontWeight: 'lighter', letterSpacing: `${letterSpacing}px`, fontSize: `${bottomleftFontSize}px`, bottom: `${bottomleftPosition}px` }}>
                            {birthdayData.name || "Title"}
                        </div>
                        <div className={styles.bottomleftsub} style={{ fontSize: `${bottomleftsubFontSize}px`, bottom: `${bottomleftsubPosition}px` }}>{"Birthday"}</div>
                    </div>
                </>
                }
            </Form.Field>

            {((!edit && !editId && !imageUrl) || (copy && !imageUrl)) &&
                <Segment padded basic>
                    <FilePicker
                        {...filePickerStatus}
                        onSelected={handleFileSelect}
                        multiple={false}
                        accept={["image/*"]}
                    />
                </Segment>
            }

            {edit && editId && !imageUrl && <Form.Field>
                <S3FileUpload folderName={`birthdays/${editId}/images`} onUploaded={(url) => handleFileUpload(url, editId)} accept={["image/*"]} />
            </Form.Field>}
            <Button primary type='submit' disabled={validForm() ? false : true}>Save</Button>
            {!copy && edit && <Button className='copyButton' type="button" onClick={() => copyClickHandler(editId)}>Copy</Button>}
            {edit && <Button type="button" color='red' onClick={deleteClickHandler}>Delete</Button>}
            <Button type="button" onClick={closeCreateBirthdayHandler}>Cancel</Button>
        </Form>
    </div>
}

export default BirthDayForm