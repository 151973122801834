import React, { useEffect, useState } from 'react'
import '../DailyAffirmations/style.less'
import {
    Dimmer,
    Loader,
    Icon,
    Tab,
    Modal,
    TransitionablePortal,
    Pagination,
    PaginationProps,
    Popup,
    Dropdown,
} from 'semantic-ui-react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import BirthDayForm from './Create'
import { deleteBirthday, getAllBirthdays } from '../../../services/Birthdays'
import { Birthday, EditBirthday } from '../../../types/Birthday'
import { getSignedBirthdayImageUrls } from '../../../services/ImageUpload'
import ListItemsPane from '../../../components/ListItemsPane'
import CalendarEventContent from '../../../components/CalendarEventContent'
import { jsonTocsvDownloader } from '../../../util/jsonTocsvDownloader';
import { toast } from 'react-toastify';
import moment from 'moment'
import { prntyrLink } from '../../../util/data';
import { sortByKey } from '../../../util/sortData'
import { User } from '../../../types'
import { updateUser } from '../../../services/Users'
import { listRoomTypes } from '../../../services/RoomTypes'
import { sendToast } from '../../../util'

interface Props {
    profile: User
}

const Birthdays = ({profile}) => {
    const [openCreateBirthDay, setOpenCreateBirthday] = useState({
        open: false,
        dob: new Date().toLocaleString("en-US"),
    })
    const [editBirthday, setEditBirthday] = useState<EditBirthday>({
        edit: false,
        name: "",
        dob: new Date().toLocaleString("en-US"),
        _id: "",
        imageUrl: "",
        imageName: "",
        audio: "",
        audioId: "",
        copy: false,
        calendarType: []
    })
    const [isLoading, setIsLoading] = useState(false)
    const [birthdayData, setBirthdayData] = useState<Birthday[]>([])
    const [viewingYear, setViewingYear] = useState<number>(new Date().getFullYear())
    const [dateRange, setDateRange] = useState({})
    const [reloadFlag, setReloadFlag] = useState(true)   
    const [birthdayDateFilterStr, setBirthdayDateFilterStr] = useState('') 
    const [listItemBirthdayData, setListItemBirthdayData] = useState<Birthday[]>([])
    const [activePageNumber, setActivePageNumber] = useState<any>(1)
    const [printBtnSpinner, setPrintBtnSpinner] = useState<Boolean>(false);
    const [allCalendarType, setAllCalendarType] = useState<{
        key: string;
        text: string;
        value: string;
    }[]>([])
    const [calendarType, setCalendarType] = useState<string[]>(profile && profile.selectedCalendarType && profile.selectedCalendarType.length && profile.selectedCalendarType || [])
    const [calendarTypeLoader, setCalendarTypeLoader] = useState<boolean>(false)
    const reload = async () => {
        setReloadFlag(!reloadFlag)
    }

    useEffect(() => {
        (async function () {
            console.log(Object.keys(dateRange))
            if (Object.keys(dateRange).length === 0) {
                return
            }
            setIsLoading(true)
            const res = await getAllBirthdays(dateRange)
            setBirthdayData(res)
            const  sortedResWithTimeKey = res.filter((birthday) => {
                const todaysDate = formatDate(new Date())
                if(getMonthAndDay(birthday.dob) >= getMonthAndDay(todaysDate)){
                    return true
                }
                return false
            }).map((birthday) => {
                    const birthYear = Number(birthday.dob.split("-")[0])
                    return {
                        ...birthday, 
                        time:birthday.dob
                    }
                }).sort((a, b) => {
                    if (a.dob > b.dob) {
                        return 1
                    }
                    if (a.dob < b.dob) {
                        return -1
                    }
                    return 0
                })
            setListItemBirthdayData(sortedResWithTimeKey)
            setIsLoading(false)
        })()
    }, [dateRange, reloadFlag])

    useEffect(() => {
        getCalendarTypes();
    }, []);

    const getCalendarTypes = async () => {
        try {
            setCalendarTypeLoader(true)
            const allCalendarType = await listRoomTypes();
            const allCalendarTypes = allCalendarType.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
            if (!allCalendarTypes || allCalendarTypes.length < 0) {
                throw new Error("Error while fetching room types.")
            }
            setAllCalendarType(allCalendarTypes)
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error while fetching room types.")
        } finally {
            setCalendarTypeLoader(false)
        }
    }

    const createBirthdayClickHandler = () => {
        setOpenCreateBirthday({
            open: true,
            dob: new Date().toISOString(),
        })
    }

    const dateClickHandler = async (payload) => {
        const clickedDate = payload.dateStr
        setEditBirthday({
            ...editBirthday,
            edit: false,
            dob: clickedDate,
            imageName:"", 
            imageUrl:"",
        })
        setOpenCreateBirthday({
            open: true,
            dob: clickedDate
        })

    }

    const selectBirthdayHandler = (id) => {
        const selectedBirthday = birthdayData.filter((birthday) => {
            return birthday._id === id
        })[0]
        return selectedBirthday
    }

    const editBirthdayHandler = async (id) => {
        const selectedBirthday = selectBirthdayHandler(id)
        let signedUrlImage;
        if (selectedBirthday.image) {
            const imageName = selectedBirthday.image
            signedUrlImage = await getSignedBirthdayImageUrls(selectedBirthday._id || "", imageName || "")
        }
        setEditBirthday({
            edit: true,
            name: selectedBirthday.name,
            dob: selectedBirthday.dob,
            _id: selectedBirthday._id || "",
            imageUrl: signedUrlImage,
            imageName:selectedBirthday.image || "",
            audio:selectedBirthday.playableUrl || "",
            audioId:selectedBirthday.audioId || "", 
            copy: false,
            calendarType: selectedBirthday.calendarType || []
        })
    }
    
    const eventClickHandler = async (payload) => {
        const selectedBirthday = selectBirthdayHandler(payload.event._def.publicId)
        let signedUrlImage;
        if (selectedBirthday.image) {
            const imageName = selectedBirthday.image
            signedUrlImage = await getSignedBirthdayImageUrls(selectedBirthday._id || "", imageName || "")
        }
        setEditBirthday({
            edit: true,
            name: selectedBirthday.name,
            dob: selectedBirthday.dob,
            _id: selectedBirthday._id || "",
            imageUrl: signedUrlImage,
            imageName:selectedBirthday.image || "",
            audioId:selectedBirthday.audioId || "",
            audio:selectedBirthday.playableUrl || "", 
            copy: false,
            calendarType: selectedBirthday.calendarType || []
        })
    }

    const dateChangeHandler = async (payload) => {
        const start = payload.startStr.slice(0, 10)
        const end = payload.endStr.slice(0, 10)
        const startYear = moment(start).year()
        setViewingYear(startYear)
        await setDateRange({ start, end })
        reload();
    }

    function getBirthYear (dob)  {
        return dob.split('-')[0]
    }

    const deleteBirthdayHandler = async (id) =>{
       if (id) {
           try {
               setIsLoading(true)
               await deleteBirthday(id)
               setIsLoading(false)
               reload()
               closeCreateBirthdayHandler()
           } catch (error) {
               if (error instanceof Error) {
                   closeCreateBirthdayHandler()
                   throw new Error(error.message)
               }
           }
       }
    }
    
    const copyClickHandler = async (id) => {
        const selectedBirthday = birthdayData.filter((birthday) => {
            return birthday._id === id
        })[0]
        let signedUrlImage = ""
        if(selectedBirthday.image){
            const imageName = selectedBirthday.image
            setIsLoading(true)
             signedUrlImage = await getSignedBirthdayImageUrls(selectedBirthday._id || "", imageName || "")
            setIsLoading(false)
            setEditBirthday({...editBirthday, imageUrl:signedUrlImage})
        }
        setEditBirthday({ edit: false, name: selectedBirthday.name, _id: selectedBirthday._id || "", dob: selectedBirthday.dob, imageUrl: signedUrlImage, imageName: selectedBirthday.image || "", audio: selectedBirthday.playableUrl || "", copy: true, audioId: selectedBirthday.audioId || "", calendarType: selectedBirthday.calendarType || [] })
        setOpenCreateBirthday({...openCreateBirthDay, open:true})
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    // date -> yyyy-mm-dd
    function getMonthAndDay(date){
       return date.split("-").splice(1, 3).join("-")
    }

    const birthdayDateFilterHandler = async (time) => {
        const formattedTime = formatDate(new Date(Date.parse(time)).toLocaleDateString("en-US")).split("-").splice(1,3).join("-")
        setBirthdayDateFilterStr(formattedTime)
        setListItemBirthdayData(birthdayData.filter((birthday) => {
            return birthday.dob.split("-").splice(1,3).join("-") === formattedTime
        }))
    }

    const clearBirthdayDateFilter = () => {
        setBirthdayDateFilterStr("")
        setListItemBirthdayData(birthdayData)
    }

    const paginationPerPageNumber = 10

    const getPaginatedBirthdays = () => {
        let filteredlistItemBirthdayData: Birthday[] = []
        if(birthdayData.length > paginationPerPageNumber){
            const startNum = (activePageNumber - 1) * paginationPerPageNumber
            const endNum = activePageNumber * paginationPerPageNumber - 1
            filteredlistItemBirthdayData =  listItemBirthdayData.filter((birthday, index) => {
                return index >= startNum && index <= endNum
            })
        }
        return filteredlistItemBirthdayData
    }

    const paginationPageChangeHandler = (e, data:PaginationProps) => {
        setActivePageNumber(data.activePage)
    }
    const panes = [
        {
            menuItem: 'Calendar',
            render: () => {
                return (
                    <div className="menu-calendar-view">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                right: 'addActivity print today prev,next'
    
                            }}
                            customButtons={{
                                addActivity : { 
                                    text: `+`,
                                    click: () => {
                                        createBirthdayClickHandler()
                                    }, 
                                },
                                print: {
                                    text: printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                    click: async (e) => {
                                        e.preventDefault();
                                        window.open(prntyrLink, '_blank');
                                        // commented the print functionality as it is not required but we might need it in future
                                        // if (printBtnSpinner) {
                                        //     toast.warn("Task in progress, please wait until it's completed.", {
                                        //         position: 'bottom-center',
                                        //         autoClose: 5000,
                                        //         hideProgressBar: false,
                                        //         closeOnClick: true,
                                        //         pauseOnHover: true,
                                        //     });
                                        //     return;
                                        // }
                                        // setPrintBtnSpinner(true);
                                        // await jsonTocsvDownloader(birthdayData, "Birthday", true);
                                        // setPrintBtnSpinner(false);
                                    }
                                }
                            }}
                            // timeZone="UTC"
                            dateClick={dateClickHandler}
                            datesSet={dateChangeHandler}
                            eventClick={eventClickHandler}
                            editable={true}
                            defaultAllDay={true}
                            showNonCurrentDates={false}
                            events={
                                birthdayData.map((el) => {
                                    const year = getBirthYear(el.dob)
                                    return el.audioId ? {
                                        title: el.name,
                                        date: el.dob.replace(String(year), String(viewingYear)),
                                        id: el._id,
                                        classNames: "audio"
                                    } : 
                                    {
                                        title: el.name,
                                        date: el.dob.replace(String(year), String(viewingYear)),
                                        id: el._id
                                    }
                                })
                            }
                            eventContent={(info) => <CalendarEventContent info={info}/>}
                        />
                    </div>
                )
            },
        },
        {
            menuItem: 'List',
            render: () => {
                return <>
                    <ListItemsPane 
                        editListItem={editBirthdayHandler} 
                        deleteListItem={deleteBirthdayHandler} 
                        loading={isLoading} 
                        listItemFilter={birthdayDateFilterStr} 
                        isSaving={false} isFetching={false} 
                        listItems={listItemBirthdayData} 
                        sourcePage="Birthdays" 
                        copyListItem={copyClickHandler} 
                        getPaginatedListItem={getPaginatedBirthdays} 
                        setListItemDateFilter={birthdayDateFilterHandler}
                        clearDateFilter={clearBirthdayDateFilter} 
                        cancelEditListItem={() => { }} 
                        handleSubmit={() => { }} 
                        handleUpdate={() => { }} />
                    <div className="pagination-holder">
                        <Pagination activePage={activePageNumber} totalPages={Math.ceil(birthdayData.length) / paginationPerPageNumber} onPageChange={paginationPageChangeHandler} />
                    </div>
                </>
            },
        },
    ]

    const closeCreateBirthdayHandler = () => {
        setOpenCreateBirthday({
            open: false,
            dob: new Date().toISOString(),
        })
        setEditBirthday({
            dob: new Date().toISOString(),
            edit: false,
            name: "",
            _id: "",
            copy:false, 
            audio:"", 
            imageName:"",
            audioId:"", 
            imageUrl: "",
            calendarType: []
        })
    }

    const handleCalendarTypeChange = async (value: string[]) => {
        try {
            setCalendarTypeLoader(true)
            setCalendarType(value)
            const staffUser = profile as unknown as User;
            const updatedStaff = {
                ...staffUser,
                selectedCalendarType: value || [],
                updateSelectedCalendarType: true
            };
            await updateUser(updatedStaff, staffUser);
        } catch (error) {
            console.log('error in handleChange', error);
            toast.error(error instanceof Error ? error.message : "Error while fetching data.");
        } finally {
            setCalendarTypeLoader(false)
        }
    }
    


    return (
        <div className="DailyActivities">
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
            </Dimmer>
            <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Dropdown
                        loading={calendarTypeLoader}
                        selection={true}
                        value={calendarType}
                        options={sortByKey(allCalendarType)}
                        placeholder="Select Room Type"
                        style={{ width: "40%" }}
                        multiple
                        onChange={(e, {value})=>{
                            handleCalendarTypeChange(value as [])
                        }}
                    />
                </div>
            <TransitionablePortal
                open={openCreateBirthDay.open || editBirthday.edit}
                transition={{ animation: 'fade down', duration: 500 }}
                closeOnDocumentClick={false}
            >
                <Modal open={openCreateBirthDay.open || editBirthday.edit} size="tiny" className="edit-item-form" onClose={closeCreateBirthdayHandler}>
                    <BirthDayForm 
                        closeCreateBirthdayHandler={closeCreateBirthdayHandler} 
                        edit={editBirthday.edit} 
                        name={editBirthday.name} 
                        dob={editBirthday.dob} 
                        editId={editBirthday._id} 
                        reload={reload} 
                        clickedDate={openCreateBirthDay.dob} 
                        imageUrl={editBirthday.imageUrl} 
                        imageName={editBirthday.imageName} 
                        audio={editBirthday.audio} 
                        audioId={editBirthday.audioId} 
                        copy={editBirthday.copy} 
                        copyClickHandler={copyClickHandler} 
                        calendarType={calendarType}
                        allCalendarType={allCalendarType}
                        selectedBirthdayCalendarType={editBirthday.calendarType}
                        /> 
                    {/* change */}
                    {/* imageName is required to fetch the image to show in edit form */}
                    {/* audio id is required to fetch the audio and show in edit form */}
                    {/* yes everything is required and is being is used */}
                </Modal>
            </TransitionablePortal>

            <Tab className="views-tabs" menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
    )
}

export default Birthdays
