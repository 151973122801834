import React from 'react'
import { Item, Modal, TransitionablePortal } from 'semantic-ui-react'
import ListItemElement from '../ListItemElement'

import './style.less'
import ActivityForm from '../ActivityForm'
import MenuFormEditor from '../MenuFormEditor'
import POTMForm from '../PplOfTheMonthForm'

interface Props {
    listItems: any[]
    isFetching: boolean
    isSaving: boolean
    sourcePage: string
    aciveFoodTab?: string
    deleteListItem: (id) => any
    editListItem: (id) => any
    copyListItem: (id) => any | undefined
    handleSubmit: (activity) => any
    handleUpdate: (activity) => any
    cancelEditListItem: (id) => any
    passAudioBlob?
    hideActionButtons?: boolean
    ItemClickHandler?: (id: string) => void
    selectedDate?: Date
    calendarType?: string[]
    allCalendarType?: {
        key: string;
        text: string;
        value: string;
    }[]
}

const ItemsList: React.FC<Props> = (props: Props) => {
    const itemsList = props.listItems.map((listItem: any) => {
        if (listItem.edit) {
            return (
                <TransitionablePortal open={listItem.edit} transition={{ animation: 'fade down', duration: 500 }}>
                    <Modal open={listItem.edit} size="tiny" className="edit-item-form">
                        {props.sourcePage === 'DailyActivities' ? (
                            <ActivityForm
                                attendanceToggle={true}
                                key={listItem._id}
                                isFetching={props.isFetching}
                                editableActivity={listItem}
                                isSaving={props.isSaving}
                                handleSubmit={props.handleSubmit}
                                handleUpdate={props.handleUpdate}
                                cancelEditActivity={props.cancelEditListItem}
                                edit={true}
                                _id={listItem._id}
                                passAudioBlob={props.passAudioBlob}
                            />
                        ) : props.sourcePage === 'StaffOfTheMonth' ||
                          props.sourcePage === 'ResidentOfTheMonth' ||
                          props.sourcePage === 'DailyAffirmations' ? ( 
                            <POTMForm
                                key={listItem._id}
                                isFetching={props.isFetching}
                                isSaving={props.isSaving}
                                // editableActivity?= Partial<PersonOfTheMonth>
                                editableActivity={listItem}
                                handleSubmit={props.handleSubmit}
                                handleUpdate={props.handleUpdate}
                                cancelEditActivity={props.cancelEditListItem}
                                edit={true}
                                _id={listItem._id}
                                // copyActivity?= PersonOfTheMonth | null;
                                sourcePage={props.sourcePage}
                                passAudioBlob={props.passAudioBlob}
                                calendarType={props.calendarType}
                                allCalendarType={props.allCalendarType}
                            />
                            ) : (
                            <MenuFormEditor
                                key={listItem._id}
                                isFetching={props.isFetching}
                                editableMenu={listItem}
                                isSaving={props.isSaving}
                                handleSubmit={props.handleSubmit}
                                handleUpdate={props.handleUpdate}
                                cancelEditMenu={props.cancelEditListItem}
                                edit={true}
                                _id={listItem._id}
                                activeFoodTab={props.aciveFoodTab}
                                passAudioBlob={props.passAudioBlob}
                            />
                        )}
                    </Modal>
                </TransitionablePortal>
            )
        }
        return props.sourcePage === 'StaffOfTheMonth' ||
            props.sourcePage === 'ResidentOfTheMonth' ||
            props.sourcePage === 'DailyAffirmations' || 
            props.sourcePage === "Birthdays" ? (
            <ListItemElement
                key={listItem._id}
                id={listItem._id}
                text={listItem.name}
                time={[listItem.startDate, listItem.endDate][0] ? [listItem.startDate, listItem.endDate] : listItem.time}
                sourcePage={props.sourcePage}
                deleteListItem={props.deleteListItem}
                editListItem={props.editListItem}
                playableUrl={listItem.playableUrl}
                copyListItem={props.copyListItem}
                hideActionButtons={props.hideActionButtons}
            />
        ) : (
            <ListItemElement
                key={listItem._id}
                id={listItem._id}
                text={(props.sourcePage === "Menu" || props.sourcePage === "DailyActivities") ? listItem.name : listItem.text}
                time={(props.sourcePage === "Menu" || props.sourcePage === "DailyActivities") ? listItem.startDate : listItem.timestamp}
                sourcePage={props.sourcePage}
                deleteListItem={props.deleteListItem}
                editListItem={props.editListItem}
                copyListItem={props.copyListItem}
                playableUrl={listItem.playableUrl}
                hideActionButtons={props.hideActionButtons}
                itemClickHandler={props.ItemClickHandler}
                svcMenuInstance={listItem.svcMenuInstance}
                svcActivityInstance={listItem.svcActivityInstance}
                selectedDate={props.selectedDate}
            />
        )
    })

    return <Item.Group relaxed>{itemsList}</Item.Group>
}

export default ItemsList
