import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dimmer, Dropdown, Form, Grid, List, Loader, Popup, Segment } from 'semantic-ui-react'
import { listCompanionData } from '../../services/Companion'
import { fetchResidentCalendarEvents } from '../../services/Registrants'
import { fetchOneFacility } from '../../services/Facilities'
import { AuthState, ResidentCalendar } from '../../types'
import { Analyze } from '../../types/Analyze'
import moment from "moment-timezone"
import AnalyzeTable from '../ResidentAnalyze/AnalyzeTable'
import ResidentDatePickers from '../ResidentAnalyze/ResidentDatePickers'
import CustomDatePicker, { DateRange } from '../CustomDatePicker'
import PrintExportIcons from '../PrintExportIcons'
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader'
import { toast } from 'react-toastify';
import { sortByKey } from '../../util/sortData';
import { Asset } from '../../types/Asset';
import { listAssets } from '../../services/Assets';
import { Scatter } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { SSL_OP_NO_TLSv1_1 } from 'constants'
import API from '../../services/API'
import { date } from 'yup'
import { addDocWidgetData } from '../../services/DocWidgets'

interface PersonalEventData extends Analyze {
    isPersonalEvent: true;
}

interface FacilityEventData extends Analyze {
    isPersonalEvent: false;
}

type AttendanceDataGrouped = {
    personal?: PersonalEventData[];
    facility?: FacilityEventData[];
};

interface EventGroup {
    date: string;
    events: Analyze[];
}
interface Props {
    residentId: string
    residentName: string
}

const ResidentCompanion: FC<Props> = ({ residentId, residentName }) => {
    const initScData = {
        datasets: [{
            label: 'Negative Conversations',
            data: [],
            backgroundColor: 'rgb(238, 75, 43)'
        },        {
            label: 'Positive Conversations',
            data: [],
            backgroundColor: `rgb(92, 230, 92)`
        },
        {
            label: 'Neutral Conversations',
            data: [],
            backgroundColor: `rgb(255, 191, 0)`
        }],
    };

    const [isLoading, setIsLoading] = useState(false)
    const [dateRange, setDateRange] = useState<DateRange>({
        start: undefined,
        end: undefined
    })
    const [scatterData, setScatterData] = useState({...initScData});
    const [scatterOptions, setScatterOptions] = useState({});
    const [isFirstRender, setIsFirstRender] = useState(true);
    const profile = useSelector((state: { authReducer: AuthState }) => state.authReducer.profile);
    const facilityTimezone = profile && profile.FacilityTimeZone || "";
    const now = Date.now();
    const millisecondsinaweek = 7*24*60*60*1000;
    const oneWeekAgo = new Date(Date.now() - millisecondsinaweek + 6000000);
    oneWeekAgo.setHours(0, 0, 0, 0);
    const initialOptions = {
        plugins: {
            datalabels: {
                display: false
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    const datEntr: any = scatterData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    const tm = new Date(tooltipItem.xLabel);
                    const yr = tm.getFullYear();
                    const mon = tm.getMonth() + 1;
                    const day = tm.getDate();
                    const hr = String(tm.getHours()).padStart(2, '0');
                    const min = String(tm.getMinutes()).padStart(2, '0');
                    const intString = datEntr.interest ? ` interest: ${datEntr.interest}` : ""
                    const wanteds: any = [];
                    for (const key in datEntr) {
                        if (datEntr[key] === true) {
                            wanteds.push(key);
                        }
                    }
                    const resultString = wanteds.join(' ');
                    return `(${mon}/${day}/${yr} ${hr}:${min}, ${tooltipItem.yLabel})${intString} ${resultString}`;
                }
            }
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        max: 30,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Conversation Length',
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        callback: function(value, index, values) {
                            const time = new Date(value);
                            const yr = time.getFullYear();
                            const mt = time.getMonth() + 1;
                            const dy = time.getDate();
                            if (index === values.length - 1) {
                                const currentTime = new Date();
                                const hr = String(currentTime.getHours()).padStart(2, '0');
                                const min = String(currentTime.getMinutes()).padStart(2, '0');
                                return `${hr}:${min}`;
                            }
                            return `${mt}/${dy}/${yr}`;
                        },
                        stepSize: 86400000,
                        min: +oneWeekAgo,
                        max: +now,
                        maxTicksLimit: 20,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Date',
                    },
                },
            ],
        },
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            try {
                const dat = await fetchDateFilterData(dateRange);
                let tempDat = {...initScData};
                let maxY = 0;
                dat.Content.Result.forEach(entry => {
                    addToScatter(entry, tempDat);
                    if (entry.ConversationLength > maxY) {
                        maxY = entry.ConversationLength;
                    }
                });
                let tempOpt = {...initialOptions}
                modifyOptions(dateRange, tempOpt, maxY);
                setScatterData(tempDat);
                setScatterOptions(tempOpt);
                setIsLoading(false)
                setIsFirstRender(false)
            } catch (error) {
                console.error(error)
                showWarningToast("Failed to get Companion information")
                setIsLoading(false)
            }
        })();
    }, []);

    useEffect(() => {
        if (isFirstRender) {
            return
        }
        (async () => {
            setIsLoading(true)
            try {
                const dat = await fetchDateFilterData(dateRange);
                let tempDat = {...initScData};
                let maxY = 0;
                dat.Content.Result.forEach(entry => {
                    addToScatter(entry, tempDat);
                    if (entry.ConversationLength > maxY) {
                        maxY = entry.ConversationLength;
                    }
                });
                let tempOpt = {...initialOptions}
                modifyOptions(dateRange, tempOpt, maxY);
                setScatterData(tempDat);
                setScatterOptions(tempOpt);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                showWarningToast("Failed to get Companion information")
                setIsLoading(false);
            }
            setIsLoading(false)
        })()
    }, [dateRange]);

    async function fetchDateFilterData({ start, end }: DateRange) {
        let realStart = start ? new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0) : null;
        let realEnd = end ? new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0) : null;
        const startDateTimeISO = realStart ? realStart.toISOString() : oneWeekAgo.toISOString();
        const endDateTimeISO = realEnd ? realEnd.toISOString() : new Date(Date.now()).toISOString();
        return await listCompanionData(residentId, startDateTimeISO, endDateTimeISO);
    }

    function addToScatter(entry, scatterData) {
        const time = new Date(entry.Date);
        const x = time.getTime();
        scatterData.datasets[entry.Sentiment+1].data.push({
            x: x,
            y: entry.ConversationLength,
            interest: entry.Interest,
            WantedMenu: entry.WantedMenu,
            WantedCalendar: entry.WantedCalendar,
            WantedNews: entry.WantedNews,
            WantedFree: entry.WantedFree,
            WantedQuestion: entry.WantedQuestion,
        });
    }

    function modifyOptions({ start, end }: DateRange, scatterOptions, maxY) {
        let realStart = start ? new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0) : null;
        scatterOptions.scales.xAxes[0].ticks.min = realStart ? realStart.getTime() : scatterOptions.scales.xAxes[0].ticks.min;
        let realEnd = end ? new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0) : null;
        scatterOptions.scales.xAxes[0].ticks.max = realEnd ? realEnd.getTime() : scatterOptions.scales.xAxes[0].ticks.max;
        scatterOptions.scales.xAxes[0].ticks.callback = end ? function(value, index, values) {
            const time = new Date(value);
            const yr = time.getFullYear();
            const mt = time.getMonth() + 1;
            const dy = time.getDate();
            return `${mt}/${dy}/${yr}`;
        } : scatterOptions.scales.xAxes[0].ticks.callback
        scatterOptions.scales.yAxes[0].ticks.max = maxY ? Math.ceil((maxY + 1) / 10) * 10 : 100;
    };

    const showWarningToast = (message: string) => {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }

    return (
        <div style={{ position: "relative" }}>
            <CustomDatePicker dateRange={dateRange} setDateRange={setDateRange} />
            {isLoading ? (
                <Loader active inline="centered" />
            ) : (
                <Segment>
                    <h2>Companion Data</h2>
                    <Scatter data={scatterData} options={scatterOptions} />
                </Segment>
            )}
        </div>
    )
}

export default ResidentCompanion