import React, { useState, useEffect } from 'react'
import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'
import {
    Dimmer,
    Loader,
    Icon,
    Tab,
    Message,
    TabProps,
    Pagination,
    PaginationProps,
    Button,
    Modal,
    TransitionablePortal,
    Dropdown,
} from 'semantic-ui-react'
import { PersonOfTheMonth, User, UserProfile } from '../../../types'
import 'flatpickr/dist/themes/airbnb.css'
import 'rc-time-picker/assets/index.css'
import {
    newPersonOfTheMonth,
    pplOfTheMonths,
    deletePersonOfTheMonth,
    getUploadUrl,
    uploadToSignedUrl,
    checkDestPlayableWithTimeDelay,
    updatePersonOfTheMonth,
} from '../../../services/PplOfTheMonth'
import POTMForm from '../../../components/PplOfTheMonthForm'
import ListItemsPane from '../../../components/ListItemsPane'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarEventContent from '../../../components/CalendarEventContent'
import { jsonTocsvDownloader } from '../../../util/jsonTocsvDownloader';
import { toast } from 'react-toastify'
import { copySignageImageFromS3, uploadSignageImageToS3 } from '../../../services/ImageUpload'
import { fetchOneFacility } from '../../../services/Facilities';
import { AppState } from '../../../reducers';
import { connect } from 'react-redux';
import moment from "moment-timezone";
import { prntyrLink } from '../../../util/data';
import Form from '../Notifications/Form'
import { sortByKey } from '../../../util/sortData'
import { listRoomTypes } from '../../../services/RoomTypes'
import { sendToast } from '../../../util'
import { updateUser } from '../../../services/Users'
interface State {
    isFetching: boolean
    error: string | null
    audioError: string | null
    isSaving: boolean
    dailyAffirmations: PersonOfTheMonth[] // activities: DailyActivity[];
    // activeActivities: DailyActivity[];
    // historyActivities: DailyActivity[];
    openCreateForm: boolean
    activityFilter: string
    activeTab: string
    activePageNumber: any
    copyActivity: PersonOfTheMonth | null
    activeTabIndex: number
    audioBlob
    monthlyAffirmations: PersonOfTheMonth[]
    selectedDateInCalendar: string | number
    selectedDailyAffirmationId?: string | undefined
    selectedDailyAffirmation?: PersonOfTheMonth | undefined
    editAffirmation: boolean
    exportBtnSpinner: boolean
    printBtnSpinner: boolean
    copyImageUrl: string | undefined
    modifyGroup?: boolean
    deleteGroup?: boolean
    showDetailedModal: boolean
    showRecurringDeleteConfirmModal: boolean
    showRecurringEditComfirmModal: boolean
    deleteRecurringBtnLoader: boolean
    deleteBtnLoader: boolean
    timezone: string
    roomTypeLoader: boolean
    calendarType: string[]
    allCalendarType: {
        key: string;
        text: string;
        value: string;
    }[]
    selectedDailyAffirmationCalendarType: string[]
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class DailyAffirmations extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            error: null,
            audioError: null,
            isSaving: false,
            dailyAffirmations: [],
            // activeActivities: [],
            // historyActivities: [],
            openCreateForm: false,
            activityFilter: '',
            activeTab: 'active',
            activePageNumber: 1,
            copyActivity: null,
            activeTabIndex: 0,
            audioBlob: undefined,
            monthlyAffirmations: [],
            selectedDateInCalendar: 0,
            selectedDailyAffirmation: undefined,
            editAffirmation: false, 
            exportBtnSpinner: false,
            printBtnSpinner: false,
            copyImageUrl: undefined,
            modifyGroup: false,
            deleteGroup: false,
            showDetailedModal: false,
            showRecurringDeleteConfirmModal: false,
            showRecurringEditComfirmModal: false,
            deleteRecurringBtnLoader: false,
            deleteBtnLoader: false,
            timezone: "America/New_York",
            roomTypeLoader: false,
            calendarType: (this.props.profile && this.props.profile.selectedCalendarType && this.props.profile.selectedCalendarType.length && this.props.profile.selectedCalendarType) || [],
            allCalendarType: [],
            selectedDailyAffirmationCalendarType: [],
        }
    }

    getDateInYYYYMMDD(input: any): string {
        return moment.tz(input || new Date(), this.state.timezone).format('YYYY-MM-DD');
    }

    openCloseCreateActivityForm(): void {
        // if (this.state.copyActivity) {
        //     this.setState({
        //         openCreateForm: !this.state.openCreateForm,
        //         copyActivity: null
        //     });
        // } else {
        this.setState({
            openCreateForm: !this.state.openCreateForm,
        })
        // }
    }

    async deleteActivity(id): Promise<void> {
        this.setState({
            ...this.state,
            isSaving: true,
        })
        try {
            await deletePersonOfTheMonth({ _id: id, srcId: undefined, deleteGroup: undefined }, 'affirmation');
            this.setState({
                selectedDailyAffirmation: undefined,
                selectedDailyAffirmationId: undefined,
            })
            await this.populateStateWithPOTM('delete')
        } catch (error) {
            this.setState({
                ...this.state,
                selectedDailyAffirmation: undefined,
                selectedDailyAffirmationId: undefined,
                isSaving: false,
                error: error,
            })
        }
    }

    async handleDeleteRecurringCommunityMessage(data): Promise<void> {
        try {
            await deletePersonOfTheMonth({ _id: data._id, srcId: data.srcId, deleteGroup: this.state.deleteGroup }, 'affirmation')
            this.setState({
                selectedDailyAffirmation: undefined,
                selectedDailyAffirmationId: undefined,
                showRecurringDeleteConfirmModal: false,
                deleteBtnLoader: false,
                deleteRecurringBtnLoader: false
            })
            await this.populateStateWithPOTM('delete')
        } catch (error) {
            this.setState({
                ...this.state,
                isSaving: false,
                error: error,
            })
        }
    }

    editActivity(id: string): void {
        const dailyAffirmations = this.state.dailyAffirmations.map((activity: PersonOfTheMonth) => {
            if (activity._id === id) activity.edit = true
            return activity
        })
        this.setState({
            ...this.state,
            dailyAffirmations,
        })
    }

    cancelEditActivity(id?: string): void {
        if (id) {
            const dailyAffirmations = this.state.dailyAffirmations.map((activity: PersonOfTheMonth) => {
                if (activity._id === id) delete activity.edit
                return activity
            })
            this.setState({
                ...this.state,
                copyActivity: null,
                selectedDailyAffirmation: undefined,
                selectedDailyAffirmationId: '',
                dailyAffirmations,
                openCreateForm: false,
                audioBlob: null,
            })
        } else {
            this.setState({
                openCreateForm: false,
                copyActivity: null,
                selectedDailyAffirmation: undefined,
                selectedDailyAffirmationId: '',
                audioBlob: null,
            })
        }
    }

    copyActivity(id: string) {
        let affirmation = this.state.dailyAffirmations.find(affirmation => affirmation._id === id);
        document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
        if (affirmation) {
            this.setState({
                openCreateForm: true,
                copyActivity: affirmation
            });
        }
    }

    passAudioBlob(audioBlob) {
        this.setState({
            audioBlob,
        })
    }

    async uploadAudiofile() {
        const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('daily_affirmations')

        const file = new File([this.state.audioBlob], audioId, {
            type: 'audio/wav',
        })

        await uploadToSignedUrl(file, signedUrl)
        return audioId
    }

    async uploadImageFile(communityMessageId: string, imageFile: File): Promise<void> {
        if (imageFile) {
            await uploadSignageImageToS3("communityMessages", communityMessageId, imageFile.name, imageFile)
        } else {
            throw new Error("Image file is not provided")
        }
    }

    async copyUploadImageFile(communityMessageId: string, copyUrl: string): Promise<void> {
        if (copyUrl) {
            await copySignageImageFromS3('communityMessages', copyUrl, communityMessageId)
        } else {
            throw new Error("url is not provided")
        }
    }

    async handleAudio(personOfTheMonth) {
        try {
            const audioId = await this.uploadAudiofile()
            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                let destPlayableExists
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'daily_affirmations',
                        Math.pow(2, i) * 1000,
                    )

                    if (destPlayableExists) {
                        break
                    }
                }

                if (destPlayableExists) {
                    console.log('audio submitting', {
                        name: personOfTheMonth.name,
                        startDate: personOfTheMonth.startDate,
                        endDate: personOfTheMonth.endDate,
                        AddedBy: this.props.profile ? this.props.profile._id : '',
                        audioId: destAudioId ? destAudioId : null,
                    })

                    try {
                        const affirmationId = await newPersonOfTheMonth('affirmation', {
                            name: personOfTheMonth.name,
                            startDate: personOfTheMonth.startDate,
                            endDate: personOfTheMonth.endDate,
                            AddedBy: this.props.profile ? this.props.profile._id : '',
                            audioId: destAudioId ? destAudioId : null,
                            recurrence: personOfTheMonth.recurrence
                        })

                        if (affirmationId) {
                            const signageAffirmationId = Array.isArray(affirmationId) 
                            ? affirmationId.sort((a,b) => a.localeCompare(b))[0]
                            : affirmationId;
                            if (personOfTheMonth.imageFile) {
                                await this.uploadImageFile(signageAffirmationId, personOfTheMonth.imageFile)
                            } else if (personOfTheMonth.copyImageUrl) {
                                await this.copyUploadImageFile(signageAffirmationId, personOfTheMonth.copyImageUrl)
                            } else if (this.state.copyImageUrl) {
                                await this.copyUploadImageFile(signageAffirmationId, this.state.copyImageUrl);
                            }
                        }
                        if (!this.state.activeTabIndex) {
                        }
                        await this.populateStateWithPOTM('save')
                    } catch (e) {
                        this.setState({
                            openCreateForm: false,
                            isSaving: false,
                            isFetching: false,
                            audioBlob: undefined,
                            error: e.message,
                        })
                    }
                } else {
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        openCreateForm: false,
                        audioError: 'Failed to process audio. Please start over',
                    })
                }
            } else {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    openCreateForm: false,
                    audioError: 'Error uploading audio. Please start over',
                })
            }
        } catch (e) {
            await this.populateStateWithPOTM('save')
            this.setState({
                isSaving: false,
                isFetching: false,
                audioBlob: undefined,
                error: e.message,
                openCreateForm: false,
            })
        }
        return false
    }

    async handleSubmit(personOfTheMonth: PersonOfTheMonth): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        if (this.state.audioBlob) {
            try {
                await this.handleAudio.bind(this)(personOfTheMonth)
            } catch (e) {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    error: e.message,
                    openCreateForm: false,
                })
            }
        } else {
            try {
               const affirmationID =  await newPersonOfTheMonth('affirmation', {
                    name: personOfTheMonth.name,
                    startDate: personOfTheMonth.startDate,
                    endDate: personOfTheMonth.endDate,
                    AddedBy: this.props.profile ? this.props.profile._id : '',
                    audioId: personOfTheMonth.audioId,
                    playableUrl: personOfTheMonth.playableUrl,
                    recurrence: personOfTheMonth.recurrence,
                   calendarType: personOfTheMonth.calendarType
                });

                if (affirmationID) {
                    const signageAffirmationId = Array.isArray(affirmationID) 
                    ? affirmationID.sort((a,b) => a.localeCompare(b))[0] 
                    : affirmationID;
                    if (personOfTheMonth.imageFile) {
                        await this.uploadImageFile(signageAffirmationId, personOfTheMonth.imageFile)
                    } else if (personOfTheMonth.copyImageUrl) {
                        await this.copyUploadImageFile(signageAffirmationId, personOfTheMonth.copyImageUrl)
                    } else if (this.state.copyImageUrl) {
                        await this.copyUploadImageFile(signageAffirmationId, this.state.copyImageUrl);
                    }
                }


                await this.populateStateWithPOTM('save')
            } catch (e) {
                await this.populateStateWithPOTM('save')
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    error: e.message,
                    openCreateForm: false,
                })
            }
        }
    }

    async handleUpdate(personOfTheMonth: PersonOfTheMonth): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        try {
            if (this.state.audioBlob) {
                try {
                    const audioId = await this.uploadAudiofile()

                    if (audioId) {
                        const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                        let destPlayableExists
                        for (let i = 0; i < 6; i++) {
                            destPlayableExists = await checkDestPlayableWithTimeDelay(
                                destAudioId,
                                'daily_affirmations',
                                Math.pow(2, i) * 1000,
                            )

                            if (destPlayableExists) {
                                break
                            }
                        }

                        if (destPlayableExists) {
                            try {
                                const res = await updatePersonOfTheMonth('affirmation', {
                                    _id: personOfTheMonth._id,
                                    name: personOfTheMonth.name,
                                    startDate: personOfTheMonth.startDate,
                                    endDate: personOfTheMonth.endDate,
                                    audioId: destAudioId ? destAudioId : null,
                                    recurrence: personOfTheMonth.recurrence,
                                    modifyGroup: this.state.modifyGroup,
                                    ...(personOfTheMonth.imageFile || personOfTheMonth.copyImageUrl ?{updateSignage: true}: {}),
                                    calendarType: personOfTheMonth.calendarType
                                })

                                if (res && personOfTheMonth._id) {
                                    const signageAffirmationId = this.state.modifyGroup ? personOfTheMonth.srcId : personOfTheMonth._id;
                                    if (personOfTheMonth.imageFile) {
                                        await this.uploadImageFile(signageAffirmationId || personOfTheMonth._id, personOfTheMonth.imageFile)
                                    } else if (personOfTheMonth.copyImageUrl) {
                                        await this.copyUploadImageFile(signageAffirmationId || personOfTheMonth._id, personOfTheMonth.copyImageUrl)
                                    }
                                }
                            } catch (e) {
                                this.setState({
                                    openCreateForm: false,
                                    isSaving: false,
                                    isFetching: false,
                                    audioBlob: undefined,
                                    error: e.message,
                                })
                            }
                        } else {
                            this.setState({
                                isSaving: false,
                                isFetching: false,
                                audioBlob: undefined,
                                openCreateForm: false,
                                audioError: 'Failed to process audio. Please start over',
                            })
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            } else {
                const res = await updatePersonOfTheMonth('affirmation', {
                    _id: personOfTheMonth._id,
                    name: personOfTheMonth.name,
                    startDate: personOfTheMonth.startDate,
                    endDate: personOfTheMonth.endDate,
                    recurrence: personOfTheMonth.recurrence,
                    modifyGroup: this.state.modifyGroup,
                    ...(personOfTheMonth.imageFile || personOfTheMonth.copyImageUrl ?{updateSignage: true}: {}),
                    calendarType: personOfTheMonth.calendarType
                })


                if (res && personOfTheMonth._id) {
                    const signageAffirmationId = this.state.modifyGroup ? personOfTheMonth.srcId : personOfTheMonth._id;
                    if (personOfTheMonth.imageFile) {
                        await this.uploadImageFile(signageAffirmationId || personOfTheMonth._id, personOfTheMonth.imageFile)
                    } else if (personOfTheMonth.copyImageUrl) {
                        await this.copyUploadImageFile(signageAffirmationId || personOfTheMonth._id, personOfTheMonth.copyImageUrl)
                    }
                }
            }

            await this.populateStateWithPOTM('update')
        } catch (e) {
            await this.populateStateWithPOTM('update')
            this.setState({
                isSaving: false,
                isFetching: false,
                error: e.message,
                audioBlob: null,
                openCreateForm: false,
            })
        }
    }

    async getDailyAffirmations(filters = {}): Promise<PersonOfTheMonth[]> {
        this.setState({ isFetching: true, error: null })
        const activities = await pplOfTheMonths('affirmation', filters)
        return activities.Result
    }

    async setActivityDateFilter(time): Promise<void> {
        const date = new Date(Date.parse(time)).toLocaleDateString('en-US')
        const allDailyAffirmations = await this.getDailyAffirmations()
        this.setState({
            activityFilter: date,
            dailyAffirmations: allDailyAffirmations.filter(
                (activity: PersonOfTheMonth) => new Date(Date.parse(activity.startDate)).toLocaleDateString('en-US') === date,
            ),
            activePageNumber: 1,
            isFetching: false
        })
    }

    async clearDateFilter() {
        this.setState({
            ...this.state,
            activityFilter: '',
        })
        await this.populateStateWithPOTM('mount')
    }

    changePage(e, data: PaginationProps): void {
        this.setState({
            activePageNumber: data.activePage,
        })
    }

    getPaginatedActivity() {
        console.log('paginated activity')
        // if (this.state.activities.length > this.paginationPerPageNumber) {
        //     const startNumber = (this.state.activePageNumber - 1) * this.paginationPerPageNumber;
        //     const endNumber = (this.state.activePageNumber) * this.paginationPerPageNumber - 1;

        //     let returnActivities = this.state.activities;

        //     if (this.state.activeTab === 'history') {
        //         returnActivities = this.state.activities.slice();
        //         returnActivities.sort((a, b) => b.timestamp - a.timestamp);
        //     }

        //     return returnActivities.filter((activity: DailyActivity, index: number) => {
        //         return index >= startNumber && index <= endNumber;
        //     });
        // }
        return this.state.dailyAffirmations
    }

    async populateStateWithPOTM(readActivityIntent) {
        try {
            const dailyAffirmations = await this.getDailyAffirmations()
            this.setState({
                ...this.state,
                isFetching: false,
                isSaving: false,
                openCreateForm: false,
                activeTab: 'active',
                dailyAffirmations,
                activityFilter: this.state.activityFilter,
                // activities: activeActivities,
                // activeActivities,
                // historyActivities,
                activePageNumber: 1,
                activeTabIndex: 0,
                selectedDateInCalendar: 0,
                audioBlob: null,
            })
        } catch (error) {
            console.error(error, error.message, error.Message)
            this.setState({
                ...this.state,
                isFetching: false,
                audioBlob: null,
                error,
            })
        }
    }

    async handleMonthChange(payload) {
        const affirmations = await this.getDailyAffirmations({
            startDate: {
                $gte: this.getDateInYYYYMMDD(payload.start),
                $lt: this.getDateInYYYYMMDD(payload.end),
            },
        })
        this.setState({
            ...this.state,
            dailyAffirmations: affirmations,
            isSaving: false,
            isFetching: false,
            openCreateForm: false,
        })
    }

    async handleDateClick(payload) {
        const date = moment.tz(payload.dateStr, this.state.timezone);
        this.setState({
            openCreateForm: true,
            selectedDateInCalendar: payload.dateStr,
            activityFilter: date,
        });
    }

    async componentDidMount() {
        await this.getCalendarTypes();
        await this.populateStateWithPOTM('mount')
        const facilityId = this.props.profile && this.props.profile.Facility;
        const facilityData: any = (facilityId && await fetchOneFacility(facilityId)) || {};
        const FacilityTimeZone = facilityData.FacilityTimeZone || "America/New_York";
        this.setState({
            timezone: FacilityTimeZone
        })
    }

    async getCalendarTypes() {
        try {
            this.setState({
                roomTypeLoader: true
            })
            const roomTypes = await listRoomTypes();
            const allCalendarType = roomTypes.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
            if (!roomTypes || roomTypes.length < 0) {
                throw new Error("Error while fetching room types.")
            }
            this.setState({
                roomTypeLoader: false,
                allCalendarType
            })
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error while fetching room types.")
        }
    }

    async handleCancel() {
        this.setState({
            ...this.state,
            openCreateForm: false,
            selectedDailyAffirmation: undefined,
            selectedDailyAffirmationId: '',
        })
    }
    async handleTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        if (data.activeIndex === 1) {
            // await this.populateStateWithPOTM('mount')

            const date =
                this.state.activityFilter && new Date(Date.parse(this.state.activityFilter)).toLocaleDateString('en-US')
            this.setState({
                activityFilter: date,
                dailyAffirmations: this.state.activityFilter
                    ? this.state.dailyAffirmations.filter(
                        (activity: PersonOfTheMonth) =>
                            new Date(activity.startDate).toLocaleDateString('en-US') === date,
                    )
                    : this.state.dailyAffirmations,
                activePageNumber: 1,
            })
        }
    }

    async handleEventClick(payload) {
        const selectedDailyAffirmation = this.state.dailyAffirmations.filter(
            (event) => event._id === payload.event._def.publicId,
        )[0];

        this.setState({
            ...this.state,
            showDetailedModal: true,
            selectedDateInCalendar: selectedDailyAffirmation.startDate,
            activityFilter: selectedDailyAffirmation.startDate,
            selectedDailyAffirmationId: payload.event._def.publicId,
            selectedDailyAffirmation: selectedDailyAffirmation,
            error: '',
        })
    }
  
    async handleCalendarTypeChange(value: string[]) {
        try {
            this.setState({
                roomTypeLoader: true,
                calendarType: value,
            });
            const staffUser = this.props.profile as unknown as User;
            const updatedStaff = {
                ...staffUser,
                selectedCalendarType: value || [],
                updateSelectedCalendarType: true
            };
            await updateUser(updatedStaff, staffUser);
        } catch (error) {
            console.log('error in handleChange', error);
            toast.error(error instanceof Error ? error.message : "Error while fetching data.");
        } finally {
            this.setState({ roomTypeLoader: false });
        }
    }
    
    // paginationPerPageNumber = 10;

    panes = [
        {
            menuItem: 'Calendar',
            render: () => {
                let exportCalendarData = async () => {
                    if (this.state.exportBtnSpinner) {
                        toast.warn("Task in progress, please wait until it's completed.", {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                        return
                    }
                    this.setState({ exportBtnSpinner: true})
                    await jsonTocsvDownloader(this.state.dailyAffirmations, "DailyAffirmations")
                    this.setState({ exportBtnSpinner: false })

                }

                return <div className="menu-calendar-view">
                    <FullCalendar
                        customButtons={{
                            addActivity : { 
                                text: `+`,
                                click: () => {
                                    this.openCloseCreateActivityForm()
                                }, 
                            },
                            export: {
                                text: this.state.exportBtnSpinner ? 'Loading...' : 'Export data',
                                click: function () {
                                    exportCalendarData();
                                }
                            }, 
                            print: {
                                text: this.state.printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                click: async () => {
                                    window.open(prntyrLink, '_blank')
                                    // commented print functionality as it is not required now but we might need it later.
                                    // if (this.state.printBtnSpinner) {
                                    //     toast.warn("Task in progress, please wait until it's completed.", {
                                    //         position: 'bottom-center',
                                    //         autoClose: 5000,
                                    //         hideProgressBar: false,
                                    //         closeOnClick: true,
                                    //         pauseOnHover: true,
                                    //     })
                                    //     return
                                    // }
                                    // this.setState({ printBtnSpinner: true })
                                    // await jsonTocsvDownloader(this.state.dailyAffirmations, "DailyAffirmations", true)
                                    // this.setState({ printBtnSpinner: false })
                                }
                            }
                        }}
                        headerToolbar={{
                            right: 'addActivity print export today prev,next'

                        }}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            editable={true}
                            events={[
                                ...this.state.dailyAffirmations.map((event) => {
                                    return event.audioId ? {
                                        title: event.name
                                            ? event.name.replace(/(<([^>]+)>)/gi, '')
                                            : 'Audio',
                                        start: moment.tz(event.startDate, this.state.timezone).unix() * 1000,
                                        end: moment.tz(event.endDate, this.state.timezone).unix() * 1000 + 86400000,
                                        id: event._id,
                                        allDay: true,
                                        classNames: "audio"
                                    } : {
                                        title: event.name
                                            ? event.name.replace(/(<([^>]+)>)/gi, '')
                                            : '',
                                        start: moment.tz(event.startDate, this.state.timezone).unix() * 1000,
                                        end: moment.tz(event.endDate, this.state.timezone).unix() * 1000 + 86400000,
                                        id: event._id,
                                        allDay: true
                                    }
                                }),
                            ]}
                            datesSet={this.handleMonthChange.bind(this)}
                            dateClick={this.handleDateClick.bind(this)}
                            eventClick={this.handleEventClick.bind(this)}
                            eventContent={(i) => <CalendarEventContent info={i} />}
                        />
                    </div>
            }

        },
        {
            menuItem: 'List',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.dailyAffirmations}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="DailyAffirmations"
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    calendarType={this.state.selectedDailyAffirmationCalendarType}
                    allCalendarType={this.state.allCalendarType}
                />
            ),
        },
    ]

    render() {
        const copyClickHandler = (copyImageUrl: string | undefined) => {
            const affirmation = this.state.dailyAffirmations.find((dailyAffirmation) => dailyAffirmation._id === this.state.selectedDailyAffirmationId)
            document.getElementsByClassName('page-header')[0].scrollIntoView({block: 'start', behavior: 'smooth'})
            if(affirmation) {
                this.setState({
                    openCreateForm:true, 
                    copyActivity:{
                        ...affirmation, 
                        startDate: this.getDateInYYYYMMDD(new Date())
                    }, 
                    selectedDailyAffirmationId: "", 
                    editAffirmation: false, 
                    copyImageUrl
                })
            }
        }
        
        return (
            <div className="DailyActivities">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Dropdown
                        loading={this.state.roomTypeLoader}
                        selection={true}
                        value={this.state.calendarType}
                        options={sortByKey(this.state.allCalendarType)}
                        placeholder="Select Calendar"
                        style={{ width: "40%" }}
                        multiple
                        onChange={(e, { value }) => {
                            this.handleCalendarTypeChange(value as [])
                        }}
                    />
                </div>
                <TransitionablePortal
                    open={this.state.openCreateForm}
                    transition={{ animation: 'fade down', duration: 500 }}
                    closeOnDocumentClick={false}
                >
                    <Modal
                        open={this.state.openCreateForm}
                        size="tiny"
                        className="edit-item-form"
                        onClose={this.handleCancel.bind(this)}
                    >
                        {this.state.selectedDailyAffirmationId ? (
                            <POTMForm
                                isFetching={this.state.isFetching}
                                isSaving={this.state.isSaving}
                                handleSubmit={this.handleSubmit.bind(this)}
                                sourcePage="DailyAffirmations"
                                passAudioBlob={this.passAudioBlob.bind(this)}
                                audioError={this.state.audioError}
                                startDate={
                                    this.state.selectedDailyAffirmation &&
                                    this.state.selectedDailyAffirmation.recurrence &&
                                    this.state.modifyGroup
                                        ? this.state.selectedDailyAffirmation.recurrence
                                              .split('\n')[0]
                                              .split('DTSTART=')[1]
                                              .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                                        : this.state.selectedDailyAffirmation &&
                                          this.state.selectedDailyAffirmation.startDate
                                        ? this.state.selectedDailyAffirmation.startDate
                                        : ''
                                }
                                endDate={
                                    this.state.selectedDailyAffirmation &&
                                    this.state.selectedDailyAffirmation.recurrence &&
                                    this.state.modifyGroup
                                        ? this.state.selectedDailyAffirmation.recurrence
                                            .split('UNTIL=')[1]
                                            .split('\n')[0]
                                            .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
                                        : this.state.selectedDailyAffirmation &&
                                          this.state.selectedDailyAffirmation.endDate
                                        ? this.state.selectedDailyAffirmation.endDate
                                        : ''
                                }
                                handleCancel={this.handleCancel.bind(this)}
                                handleUpdate={this.handleUpdate.bind(this)}
                                edit={this.state.editAffirmation}
                                _id={this.state.selectedDailyAffirmationId}
                                editableActivity={this.state.selectedDailyAffirmation}
                                cancelEditActivity={this.cancelEditActivity.bind(this)}
                                copyClickHandler={copyClickHandler}
                                modifyGroup={this.state.modifyGroup}
                                calendarType={this.state.selectedDailyAffirmationCalendarType}
                                allCalendarType={this.state.allCalendarType}
                            />
                        ) : (
                            <POTMForm
                                isFetching={this.state.isFetching}
                                isSaving={this.state.isSaving}
                                handleSubmit={this.handleSubmit.bind(this)}
                                sourcePage="DailyAffirmations"
                                passAudioBlob={this.passAudioBlob.bind(this)}
                                audioError={this.state.audioError}
                                startDate={
                                    this.state.copyActivity
                                        ? this.getDateInYYYYMMDD(this.state.copyActivity.startDate)
                                        : this.state.selectedDateInCalendar
                                        ? this.getDateInYYYYMMDD(this.state.selectedDateInCalendar)
                                        : ''
                                }
                                endDate={
                                    this.state.copyActivity
                                        ? this.getDateInYYYYMMDD(this.state.copyActivity.endDate)
                                        : this.state.selectedDateInCalendar
                                        ? this.getDateInYYYYMMDD(this.state.selectedDateInCalendar)
                                        : ''
                                }
                                handleCancel={this.handleCancel.bind(this)}
                                handleUpdate={this.handleUpdate.bind(this)}
                                cancelEditActivity={this.cancelEditActivity.bind(this)}
                                copyItem={this.state.copyActivity}
                                calendarType={this.state.selectedDailyAffirmationCalendarType}
                                allCalendarType={this.state.allCalendarType}
                            />
                        )}
                    </Modal>
                </TransitionablePortal>

                {this.state.error && <Message negative>{this.state.error}</Message>}

                <Tab
                    className="views-tab"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.panes}
                    onTabChange={this.handleTabChange.bind(this)}
                />
                <Modal open={this.state.showDetailedModal}>
                    <Modal.Header>
                        {this.state.selectedDailyAffirmation && this.state.selectedDailyAffirmation.name}
                    </Modal.Header>
                    <Modal.Actions>
                        <Button
                            basic
                            color="blue"
                            onClick={(e) => {
                                e.preventDefault();
                                if (
                                    this.state.selectedDailyAffirmation &&
                                    this.state.selectedDailyAffirmation.recurrence
                                ) {
                                    this.setState({
                                        showDetailedModal: false,
                                        showRecurringEditComfirmModal: true,
                                    });
                                } else {
                                    this.setState({
                                        showDetailedModal: false,
                                        openCreateForm: true,
                                        editAffirmation: true,
                                    });
                                }
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            loading={this.state.deleteBtnLoader}
                            basic
                            color="red"
                            onClick={async (e) => {
                                e.preventDefault();
                                if (
                                    this.state.selectedDailyAffirmation &&
                                    this.state.selectedDailyAffirmation.recurrence
                                ) {
                                    this.setState({
                                        showRecurringDeleteConfirmModal: true,
                                        showDetailedModal: false,
                                    });
                                } else {
                                    this.setState(
                                        {
                                            deleteBtnLoader: true,
                                        },
                                        async () => {
                                            await this.deleteActivity(this.state.selectedDailyAffirmationId);
                                        },
                                    );
                                    this.setState({
                                        showDetailedModal: false,
                                        deleteBtnLoader: false,
                                    });
                                }
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showDetailedModal: false,
                                    selectedDateInCalendar: 0,
                                    selectedDailyAffirmationId: undefined,
                                    selectedDailyAffirmation: undefined,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.showRecurringDeleteConfirmModal}>
                    <Modal.Header>Delete recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.deleteBtnLoader}
                            basic
                            color="blue"
                            onClick={async (e) => {
                                e.preventDefault();
                                this.setState(
                                    {
                                        deleteBtnLoader: true,
                                        deleteGroup: false,
                                    },
                                    async () => {
                                        await this.deleteActivity(this.state.selectedDailyAffirmationId);
                                        this.setState({
                                            showRecurringDeleteConfirmModal: false,
                                            deleteBtnLoader: false,
                                        });
                                    },
                                );
                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.deleteRecurringBtnLoader}
                            basic
                            color="red"
                            onClick={async (e) => {
                                e.preventDefault();
                                this.setState(
                                    {
                                        deleteRecurringBtnLoader: true,
                                        deleteGroup: true,
                                    },
                                    async () => {
                                        await this.handleDeleteRecurringCommunityMessage({
                                            _id: this.state.selectedDailyAffirmationId,
                                            srcId:
                                                this.state.selectedDailyAffirmation &&
                                                this.state.selectedDailyAffirmation.srcId,
                                        });
                                    },
                                );
                            }}
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringDeleteConfirmModal: false,
                                    showDetailedModal: true,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal open={this.state.showRecurringEditComfirmModal}>
                    <Modal.Header>Edit recurring event?</Modal.Header>
                    <Modal.Actions>
                        <Button
                            loading={this.state.deleteBtnLoader}
                            basic
                            color="blue"
                            onClick={async (e) => {
                                e.preventDefault();

                                if (this.state.selectedDailyAffirmation) {
                                    const { recurrence, ...remainingProps } = this.state.selectedDailyAffirmation;
                                    this.setState({
                                        showRecurringEditComfirmModal: false,
                                        modifyGroup: false,
                                        openCreateForm: true,
                                        editAffirmation: true,
                                        selectedDailyAffirmation: remainingProps,
                                    });
                                } else {
                                    this.setState({
                                        showRecurringEditComfirmModal: false,
                                        modifyGroup: false,
                                        openCreateForm: true,
                                        editAffirmation: true,
                                    });
                                }

                            }}
                        >
                            This event
                        </Button>
                        <Button
                            loading={this.state.deleteRecurringBtnLoader}
                            basic
                            color="red"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditComfirmModal: false,
                                    modifyGroup: true,
                                    openCreateForm: true,
                                    editAffirmation: true,
                                });
                            }}
                        >
                            All events
                        </Button>
                        <Button
                            basic
                            color="grey"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    showRecurringEditComfirmModal: false,
                                    showDetailedModal: true,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

export default withRouter(connect(mapStateToProps)(DailyAffirmations));
